import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShoppingCart,
  faFolder,
  faPlusCircle,
  faBars,
  faUser,
  faSignOutAlt,
  faTimes,
  faTachometerAlt,
  faLock,
  faBox,
  faCogs,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Layout, Menu, theme, message, Button } from "antd";
import { useDispatch } from "react-redux";
import Dashboard from "../dashboard/Dashboard";
import Items from "../items/Items";
import Category from "../category/Category";
import Elements from "../elements/Elements";
import WareHouse from "../warehouse/WareHouse";
import AddValue from "../elements/addvalue/AddValue";
import AddMembers from "../members/AddMembers";
import ManageProducts from "../products/ManageProducts";
import ManagePermission from "../permission/ManagePermission";
import ManageMember from "../members/ManageMember";
import AddProducts from "../products/AddProduct";
import AddPermission from "../permission/AddPermission";
import StoreKeeperPassword from "../storeKeeper/storeKeeperPassword/StoreKeeperPassword";
import StoreKeeperProfile from "../storeKeeper/storeKeeperProfile/StoreKeeperProfile";
import { LOGOUT_STORE } from "../../constants/actionTypes";

const { Content, Sider, Header } = Layout;
const { SubMenu } = Menu;

const Navbar = () => {
  const location = useLocation();
  const isDesktop = window.innerWidth > 992;
  const [collapsed, setCollapsed] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const handleLogout = () => {
    dispatch({ type: LOGOUT_STORE });
    message.success("Storekeeper logout successfully!");
    navigate("/");
  };

  const menuItems = [
    {
      key: "1",
      icon: <FontAwesomeIcon icon={faTachometerAlt} />,
      label: "Dashboard",
      link: "/storeKeeper/dashboard",
    },
    {
      key: "2",
      icon: <FontAwesomeIcon icon={faShoppingCart} />,
      label: "Items",
      link: "/storeKeeper/items",
    },
    {
      key: "3",
      icon: <FontAwesomeIcon icon={faFolder} />,
      label: "Category",
      link: "/storeKeeper/category",
    },
    // {
    //   key: "4",
    //   icon: <FontAwesomeIcon icon={faBank} />,
    //   label: "Warehouse",
    //   link: "/storeKeeper/warehouse",
    // },
    {
      key: "4",
      icon: <FontAwesomeIcon icon={faCogs} />,
      label: "Elements",
      link: "/storeKeeper/element",
    },
    {
      key: "5",
      icon: <FontAwesomeIcon icon={faBox} />,
      label: "Products",
      subMenu: [
        {
          key: "5-1",
          label: "Add Products",
          link: "/storeKeeper/add-products",
          icon: <FontAwesomeIcon icon={faPlusCircle} />,
        },
        {
          key: "5-2",
          label: "Manage Products",
          link: "/storeKeeper/manage-products",
          icon: <FontAwesomeIcon icon={faBars} />,
        },
      ],
    },
    // {
    //   key: "7",
    //   icon: <FontAwesomeIcon icon={faListAlt} />,
    //   label: "Members",
    //   subMenu: [
    //     {
    //       key: "7-1",
    //       label: "Add Members",
    //       link: "/storeKeeper/add-members",
    //       icon: <FontAwesomeIcon icon={faUser} />,
    //     },
    //     {
    //       key: "7-2",
    //       label: "Manage Members",
    //       link: "/storeKeeper/manage-members",
    //       icon: <FontAwesomeIcon icon={faBars} />,
    //     },
    //   ],
    // },
    // {
    //   key: "8",
    //   icon: <FontAwesomeIcon icon={faClipboardCheck} />,
    //   label: "Permission",
    //   subMenu: [
    //     {
    //       key: "8-1",
    //       label: "Add Permission",
    //       link: "/storeKeeper/add-permission",
    //       icon: <FontAwesomeIcon icon={faClipboardCheck} />,
    //     },
    //     {
    //       key: "8-2",
    //       label: "Manage Permission",
    //       link: "/storeKeeper/manage-permission",
    //       icon: <FontAwesomeIcon icon={faBars} />,
    //     },
    //   ],
    // },
    {
      key: "6",
      icon: <FontAwesomeIcon icon={faUser} />,
      label: "Profile",
      subMenu: [
        {
          key: "6-1",
          label: "Profile",
          link: "/storeKeeper/storeKeeper-profile",
          icon: <FontAwesomeIcon icon={faUser} />,
        },
        {
          key: "6-2",
          label: "Change Password",
          link: "/storeKeeper/storeKeeper-change-password",
          icon: <FontAwesomeIcon icon={faLock} />,
        },
      ],
    },
    {
      key: "7",
      label: "Logout",
      icon: <FontAwesomeIcon icon={faSignOutAlt} />,
      onClick: handleLogout,
    },
  ];

  const carouselStyle = {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
  };

  return (
    <Layout>
      <Sider
        // breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
        width={260}
        style={{
          ...carouselStyle,
          overflowY: "auto",
          height: "100vh",
          position: isDesktop ? "fixed" : "static",
        }}
        collapsible
        collapsed={collapsed}
        trigger={null}
      >
        <div className="sider-header">
          <h3 className="text-xl text-white text-center p-3 font-bold">
            Inventory Management
          </h3>
        </div>
        <Menu theme="dark" mode="inline">
          {menuItems.map((item) =>
            item.subMenu ? (
              <SubMenu key={item.key} icon={item.icon} title={item.label}>
                {item.subMenu.map((subItem) => (
                  <Menu.Item
                    key={subItem.key}
                    icon={subItem.icon}
                    style={{ paddingLeft: "30px" }}
                  >
                    <Link to={subItem.link}>{subItem.label}</Link>
                  </Menu.Item>
                ))}
              </SubMenu>
            ) : (
              <Menu.Item
                key={item.key}
                icon={item.icon}
                onClick={item.key === "7" ? item.onClick : null}
              >
                <Link to={item.link}>{item.label}</Link>
              </Menu.Item>
            )
          )}
        </Menu>
      </Sider>

      <Layout>
        {isDesktop ? null : (
          <Header
            style={{
              padding: 0,
              background: colorBgContainer,
            }}
          >
            <Button
              type="text"
              icon={
                collapsed ? (
                  <FontAwesomeIcon icon={faTimes} />
                ) : (
                  <FontAwesomeIcon icon={faBars} style={{ color: "#000" }} />
                )
              }
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: "16px",
                width: 64,
                height: 64,
              }}
            />
          </Header>
        )}
        <Content
          style={{
            marginLeft: isDesktop ? 260 : 0,
            minHeight: "100vh",
            overflow: "auto",
          }}
        >
          <div
            style={{
              minHeight: 450,
            }}
          >
            {location.pathname === "/storeKeeper/dashboard" && <Dashboard />}
            {location.pathname === "/storeKeeper/items" && <Items />}
            {location.pathname === "/storeKeeper/category" && <Category />}
            {location.pathname === "/storeKeeper/warehouse" && <WareHouse />}
            {location.pathname === "/storeKeeper/element" && <Elements />}
            {location.pathname === "/storeKeeper/addvalue" && <AddValue />}
            {location.pathname === "/storeKeeper/add-members" && <AddMembers />}
            {location.pathname === "/storeKeeper/manage-products" && (
              <ManageProducts />
            )}
            {location.pathname === "/storeKeeper/manage-permission" && (
              <ManagePermission />
            )}
            {location.pathname === "/storeKeeper/manage-members" && (
              <ManageMember />
            )}
            {location.pathname === "/storeKeeper/add-products" && (
              <AddProducts />
            )}
            {location.pathname === "/storeKeeper/add-permission" && (
              <AddPermission />
            )}
            {location.pathname ===
              "/storeKeeper/storeKeeper-change-password" && (
              <StoreKeeperPassword />
            )}
            {location.pathname === "/storeKeeper/storeKeeper-profile" && (
              <StoreKeeperProfile />
            )}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Navbar;
