import {
GET_ADMIN_BOOKING,
APPROVE_BOOKING
  } from "../../../constants/actionTypes";
  import * as api from "../../../api";
  
  export const getAdminBooking= (params) => async (dispatch) => {
    try {
      const { data } = await api.getAdminBooking(params);
      dispatch({ type: GET_ADMIN_BOOKING, payload: data });
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  export const approveBooking = (bookInfo) => async (dispatch) => {
    try {
      const { data } = await api.approveBooking(bookInfo);
      dispatch({ type: APPROVE_BOOKING, payload: data });
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };