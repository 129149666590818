import * as actionTypes from "../../../constants/actionTypes";

const initialState = {
  booking: [],
  state: "idle",
  error: null,
  success:null
};

export const adminBookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ADMIN_BOOKING:
      return {
        ...state,
        booking: action.payload,
      };
      case actionTypes.APPROVE_BOOKING:
        return {
          ...state,
          success: action.payload,
          error: null,
        };

    default:
      return state;
  }
};

export default adminBookingReducer;
