import React, { useState } from "react";
import { Form, Input, Button, message, Breadcrumb, Row, Col } from "antd";
import { useDispatch } from "react-redux";
import { changePasswordEmployee } from "../../../actions/employee/changePassword/changePassword";

const EmployeePassword = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [successMsg, setSuccessMsg] = useState(false);
  const [loading, setLoading] = useState(false);

  const validatePassword = (_, value) => {
    if (value && value.length < 8) {
      return Promise.reject(
        new Error("Password must be at least 8 characters long")
      );
    }
    return Promise.resolve();
  };

  const onFinish = async (values) => {
    console.log("Received value", values);
    try {
      setLoading(true);

      const res = await dispatch(changePasswordEmployee(values));
      if (res.success) {
        message.success(res.message);
        setSuccessMsg(true);
        form.resetFields();
        setTimeout(() => {
          setSuccessMsg(false);
        }, 2000);
      }
    } catch (error) {
      console.log(error);
      message.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
     
      <div className="flex justify-between items-center pt-5 pl-4 pr-4">
        <h2 className="text-lg font-semibold text-gray-800">Change Password</h2>
        <Breadcrumb>
          <Breadcrumb.Item>
            {" "}
            <a href="/employee/dashboard">Home</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Change Password</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="bg-white rounded-lg p-6 shadow-md m-5">
      {successMsg && (
        <div style={{ color: "green" }}>Password change successfully</div>
      )}
        <Form
          form={form}
          layout="vertical"
          style={{ maxWidth: "100%", margin: "0 auto" }}
          onFinish={onFinish}
        >
          <Row gutter={16}>
            <Col xs={24} sm={12} style={{ width: "200px" }}>
              <Form.Item
                label="Current Password"
                name="currentPassword"
                rules={[
                  {
                    required: true,
                    message: "Please enter your current password",
                  },
                  {
                    validator: validatePassword,
                  },
                ]}
              >
                <Input.Password placeholder="Current Password" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} style={{ width: "200px" }}>
              <Form.Item
                label="New Password"
                name="newPassword"
                rules={[
                  { required: true, message: "Please enter yournew password" },
                  {
                    validator: validatePassword,
                  },
                ]}
              >
                <Input.Password placeholder="New Password" />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              className="bg-blue-500 text-white"
            >
              Change Password
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default EmployeePassword;
