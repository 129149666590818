import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  Button,
  Table,
  Input,
  Modal,
  message,
} from "antd";
import {
  DownloadOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import QRCode from "react-qr-code";
import { useDispatch } from "react-redux";
import { getMaintenanceEmployee } from "../../../actions/maintenance/employee/employee";



const Employees = () => {
  const dispatch = useDispatch();

  // const member = useSelector((state) => state.addMember.members);
  // console.log(member);

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [printData, setPrintData] = useState([]);
  const [isPrintModalVisible, setIsPrintModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [qrCodeModalVisible, setQrCodeModalVisible] = useState(false);
  const [selectedQrCode, setSelectedQrCode] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [totalPage, setTotalPage] = useState("");

  const columns = [
    {
      title: "SNo",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
    },
    {
      title: "Post",
      dataIndex: "post",
      key: "post",
    },
    {
      title: "Attendance Id",
      dataIndex: "attendanceId",
      key: "attendanceId",
    },
    {
      title: "QR Code",
      key: "qrcode",
      render: (_, record) => (
        <QRCode
          size={50}
          value={record.qrImage}
          style={{ cursor: "pointer" }}
          onClick={() => handleQRCodeClick(record.qrImage)}
        />
      ),
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
   
      try {
        setLoading(true);
        const params = {
          page: currentPage,
          recordLimit: pageSize,
        };
        const result = await dispatch(getMaintenanceEmployee(params));
        setData(result.data);
        setTotalPage(result.totalPage)
      } catch (error) {
        console.error("Error fetching employee:", error);
        message.error(error.response.data.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, pageSize,currentPage]);

  const csvData = [
    ["Name", "Email", "Mobile Number", "Department", "Post", "Attendance Id"],
    ...data?.map((item) => [
      item.name,
      item.email,
      item.mobileNumber,
      item.department,
      item.post,
      item.attendanceId,
    ]),
  ];

  const arrayToCSV = (arr) => {
    return arr
      .map((row) => row.map((cell) => `"${cell}"`).join(","))
      .join("\n");
  };

  const downloadCSV = () => {
    const csvString = arrayToCSV(csvData);
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "employees.csv";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  const handlePrint = () => {
    setPrintData(data);
    setIsPrintModalVisible(true);
  };
  // console.log(printData);

  const handlePrintModalCancel = () => {
    setIsPrintModalVisible(false);
  };

  const printTable = () => {
    const printWindow = window.open("", "_blank");
    printWindow.document.open();
    printWindow.document.write("<html><head><title>Print</title>");

    // Add custom CSS styles here
    printWindow.document.write(`
      <style>
        table {
          width: 100%; 
        }
        th, td {
          padding: 10px;
          border: 1px solid #000; 
        }
      </style>
    `);

    printWindow.document.write("</head><body>");
    printWindow.document.write(
      '<h1 style="text-align: center;">Printed Table</h1>'
    );
    printWindow.document.write('<table border="1" style="margin: 0 auto;">');
    printWindow.document.write("<tr>");

    columns
      .filter((column) => column.key !== "action")
      .forEach((column) => {
        printWindow.document.write(`<th>${column.title}</th>`);
      });
    printWindow.document.write("</tr>");
    printData.forEach((record) => {
      printWindow.document.write("<tr>");

      columns
        .filter((column) => column.key !== "action")
        .forEach((column) => {
          printWindow.document.write(`<td>${record[column.dataIndex]}</td>`);
        });
      printWindow.document.write("</tr>");
    });

    printWindow.document.write("</table>");
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  };

  const filterData = () => {
    if (searchQuery.trim() === "") {
      setFilteredData(null);
    } else {
      const filtered = data?.filter((item) => {
        return (
          item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.mobileNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.department.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.post.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.attendanceId.toLowerCase().includes(searchQuery.toLowerCase())
        );
      });
      setFilteredData(filtered);
    }
  };

  useEffect(() => {
    filterData();
  }, [searchQuery]);

  const handleQRCodeClick = (qrCode) => {
    setSelectedQrCode(qrCode);
    setQrCodeModalVisible(true);
  };

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  return (
    <div>
    
      <div className="flex justify-between items-center pt-5 pl-4 pr-4">
        <h2 className="text-lg font-semibold text-gray-800">Employees</h2>
        <Breadcrumb>
        <Breadcrumb.Item>
            <a href="/maintenance/dashboard">
             Home
            </a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Employees</Breadcrumb.Item>
        </Breadcrumb>
      
       
      </div>
      <div className="bg-white rounded-lg p-6 shadow-md m-5">

      <div style={{ marginTop: "30px" }}>
        <div className="button-container">
          <div className="mobile-buttons">
            <Button
              type="primary"
              className="mobile-button bg-blue-500 text-white"
              onClick={downloadCSV}
            >
              <DownloadOutlined /> Export
            </Button>
            <Button
              type="primary"
              className="mobile-button bg-blue-500 text-white"
              onClick={handlePrint}
            >
              <PrinterOutlined /> Print
            </Button>
          </div>

          <div className="mobile-search">
            <Input.Search
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <Table
            columns={columns}
            dataSource={filteredData || data}
            loading={loading}
            pagination={{
              pageSize: pageSize,
              current: currentPage,
              onChange: handlePageChange,
              total: totalPage * pageSize,
            }}
          />
        </div>
      </div>

      <Modal
        title="Print Table"
        visible={isPrintModalVisible}
        onOk={printTable}
        onCancel={handlePrintModalCancel}
        okButtonProps={{className:"bg-blue-500 text-white"}}
      >
        <p>Review the table data below before printing:</p>
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <Table columns={columns} dataSource={printData} pagination={false} />
        </div>
      </Modal>
      <Modal
        title="QR Code"
        visible={qrCodeModalVisible}
        onCancel={() => setQrCodeModalVisible(false)}
        footer={null}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "500px",
        }}
      >
        <QRCode id="qrCodeImage" size={200} type="svg" value={selectedQrCode} />
      </Modal>
    </div>
    </div>
  );
};

export default Employees;
