import React, { useState, useEffect } from "react";
import { Card, Col, Row, Spin } from "antd";
import { Breadcrumb } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getCloseTicket, getOpenTicket, getTicketNumber } 
from "../../../actions/maintenance/dashboard/dashboard";

const cardStyle = {
  marginBottom: "10px",
};

const Dashboard = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const open=useSelector((state)=>state.maintenanceDashboard.openTicket);
  const close=useSelector((state)=>state.maintenanceDashboard.closeTicket);
  const ticketNumber=useSelector((state)=>state.maintenanceDashboard.ticketNumber);

  useEffect(() => {
    const fetchData = async () => {
    
      try {
        setLoading(true);
       await dispatch(getOpenTicket());
       await dispatch(getCloseTicket());
       await dispatch(getTicketNumber());
      } catch (error) {
        console.error("Error fetching tickets:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);

  return (
    <div>
    
      <div className="flex justify-between items-center pt-5 pl-4 pr-4">
        <h2 className="text-lg font-semibold text-gray-800">Dashboard</h2>
        <Breadcrumb>
        <Breadcrumb.Item>
            <a href="/maintenance/dashboard">
             Home
            </a>
          </Breadcrumb.Item>
        
          <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
        </Breadcrumb>
       
      </div>
      <div className="bg-white rounded-lg p-6 shadow-md m-5">
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin size="small" />
        </div>
      ) : (
        <>
          <Row gutter={16} justify="start">
            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8}}>
              <Card
                title="Ticket Number"
                bordered={false}
                style={{ background: "#fff2cd", ...cardStyle }}
              >
                {ticketNumber.data}
              </Card>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }}>
              <Card
                title="Open Ticket"
                bordered={false}
                style={{ background: "#feede7", ...cardStyle }}
              >
              {open.data}
              </Card>
            </Col>
           
           
            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8}}>
              <Card
                title="Close Ticket"
                bordered={false}
                style={{ background: "#dbf9f0", ...cardStyle }}
              >
               {close.data}
              </Card>
            </Col>
         
          </Row>
         
        </>
      )}
      </div>
    </div>
  );
};

export default Dashboard;
