import React, { useState, useEffect } from "react";
import { Card, Col, Row, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { Breadcrumb } from "antd";
import {
  getTotalAsset,
  getTotalCategory,
  getTotalEmployee,
  getTotalMember,
} from "../../../actions/admin/dashboard/dashboard";
import { useDispatch } from "react-redux";

const cardStyle = {
  marginBottom: "10px",
};

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [totalCategory, setTotalCategory] = useState("");
  const [totalAsset, setTotalAsset] = useState("");
  const [totalEmployee, setTotalEmployee] = useState("");
  const [totalMember, setTotalMember] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await dispatch(getTotalAsset());
        setTotalAsset(result.data);
      } catch (error) {
        console.error("Error fetching assets:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await dispatch(getTotalCategory());
        setTotalCategory(result.data);
      } catch (error) {
        console.error("Error fetching assets:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await dispatch(getTotalEmployee());
        setTotalEmployee(result.data);
      } catch (error) {
        console.error("Error fetching assets:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await dispatch(getTotalMember());
        setTotalMember(result.data);
      } catch (error) {
        console.error("Error fetching assets:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);

  const handleCardClick = (path) => {
    navigate(path);
  };

  return (
    <div>
      <div className="flex justify-between items-center pt-5 pl-4 pr-4">
        <h2 className="text-lg font-semibold text-gray-800">Dashboard</h2>
        <Breadcrumb>
          <Breadcrumb.Item>
            {" "}
            <a href="/admin/dashboard">Home</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="bg-white rounded-lg p-6 shadow-md m-5">
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin size="small" />
          </div>
        ) : (
          <>
            <Row gutter={16} justify="start">
              <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
                <Card
                  title="Total Items"
                  bordered={false}
                  style={{ background: "#fff2cd", ...cardStyle }}
                  onClick={() => handleCardClick('/admin/assets')}
                >
                  {totalAsset}
                </Card>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
                <Card
                  title="Total Category"
                  bordered={false}
                  style={{ background: "#feede7", ...cardStyle }}
                  onClick={() => handleCardClick('/admin/assets-category')}
                >
                  {totalCategory}
                </Card>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
                <Card
                  title="Total Employees"
                  bordered={false}
                  style={{ background: "#dbf9f0", ...cardStyle }}
                  onClick={() => handleCardClick('/admin/all-employees')}
                >
                  {totalEmployee}
                </Card>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }}>
                <Card
                  title="Total Members"
                  bordered={false}
                  style={{ background: "#f9d9f9", ...cardStyle }}
                >
                  {totalMember}
                </Card>
              </Col>
            </Row>
          </>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
