import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  Button,
  Table,
  Form,
  Modal,
  Space,
  message,
  Select,
  Input,
} from "antd";
import {  EditOutlined ,PrinterOutlined} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import {
  getMaintenanceBooking,
  updateBooking,
} from "../../../actions/maintenance/booking/booking";

dayjs.extend(utc);
dayjs.extend(timezone);

const { Option } = Select;

const Booking = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [printData, setPrintData] = useState([]);
  const [isPrintModalVisible, setIsPrintModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState(5);
  const [currentPage,setCurrentPage]=useState(1);
  const [totalPage,setTotalPage]=useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState("");
  const [id, setId] = useState("");

  function convertUtcToLocal(
    utcTimestamp,
    timeZone = "Asia/Kolkata",
    outputFormat = "HH:mm:ss"
  ) {
    const utcTime = dayjs.utc(utcTimestamp);
    const localTime = utcTime.tz(timeZone).format(outputFormat);
    return localTime;
  }

  const formatDateString = (inputDate) => {
    const dateObject = new Date(inputDate);

    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1; // Months are zero-based, so we add 1
    const year = dateObject.getFullYear();

    // Pad single-digit day or month with a leading zero
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    // Return the formatted date string
    return `${formattedDay}-${formattedMonth}-${year}`;
  };

  const columns = [
    {
      title: "SNo",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Venue",
      dataIndex: "venue",
      key: "venue",
    },
    {
      title: "Booking For",
      dataIndex: "bookingFor",
      key: "bookingFor",
    },
    {
      title: "Booking Status",
      dataIndex: "bookingStatus",
      key: "bookingStatus",
    },
    {
      title: "Booking Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => (createdAt ? formatDateString(createdAt) : null),
    },
    {
      title: "Start Time",
      dataIndex: "startTime",
      key: "startTime",
      render: (startTime) =>
        startTime ? convertUtcToLocal(startTime, "Asia/Kolkata") : null,
    },
    {
      title: "End Time",
      dataIndex: "endTime",
      key: "endTime",
      render: (endTime) =>
        endTime ? convertUtcToLocal(endTime, "Asia/Kolkata") : null,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <EditOutlined
            style={{ fontSize: "16px" }}
            onClick={() => handleEdit(record)}
          />
        </Space>
      ),
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
     
      try {
        setLoading(true);
           const params = {
          recordLimit: pageSize,
          page:currentPage
        };
        const res = await dispatch(getMaintenanceBooking(params));
        setData(res.data);
        setTotalPage(res.totalPage)
      } catch (error) {
        console.error("Error fetching booking:", error);
        message.error(error.response.data.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch,pageSize,currentPage]);

  const handlePrint = () => {
    setPrintData(data);
    setIsPrintModalVisible(true);
  };
  // console.log(printData);

  const handlePrintModalCancel = () => {
    setIsPrintModalVisible(false);
  };

  const printTable = () => {
    const printWindow = window.open("", "_blank");
    printWindow.document.open();
    printWindow.document.write("<html><head><title>Print</title>");
  
    // Add custom CSS styles here
    printWindow.document.write(`
      <style>
        table {
          width: 100%; 
        }
        th, td {
          padding: 10px;
          border: 1px solid #000; 
        }
      </style>
    `);
  
    printWindow.document.write("</head><body>");
    printWindow.document.write(
      '<h1 style="text-align: center;">Printed Table</h1>'
    );
    printWindow.document.write('<table border="1" style="margin: 0 auto;">');
  
    // Header row
    printWindow.document.write("<tr>");
    printWindow.document.write(`<th>SNo</th>`);
    printWindow.document.write(`<th>Booking Date</th>`);
    printWindow.document.write(`<th>Start Time</th>`);
    printWindow.document.write(`<th>End Time</th>`);
    printWindow.document.write(`<th>Approval</th>`);
    
    columns
      .filter((column) =>   
        column.key !== "action" &&
        column.key !== "avatar" &&
        column.key !== "startTime" &&
        column.key !== "index" &&
        column.key !== "endTime" &&
        column.key !== "createdAt" &&
        column.key !== "adminApproval"  
      )
      .forEach((column) => {
        printWindow.document.write(`<th>${column.title}</th>`);
      });
    printWindow.document.write("</tr>");
  
    // Data rows
    const filteredPrintData = printData.filter((record) => record.adminApproval === "Approve");
  
    filteredPrintData.forEach((record, index) => {
      printWindow.document.write("<tr>");
  
      // Show SNo, Booking Date, Start Time, End Time
      printWindow.document.write(`<td>${index + 1}</td>`);
      printWindow.document.write(`<td>${formatDateString(record.createdAt)}</td>`);
      printWindow.document.write(`<td>${convertUtcToLocal(record.startTime, "Asia/Kolkata")}</td>`);
      printWindow.document.write(`<td>${convertUtcToLocal(record.endTime, "Asia/Kolkata")}</td>`);
      printWindow.document.write("<td>Approve</td>"); // Since we are already filtering for "Approve"
      
      columns
        .filter(
          (column) =>
            column.key !== "action" &&
            column.key !== "avatar" &&
            column.key !== "startTime" &&
            column.key !== "index" &&
            column.key !== "endTime" &&
            column.key !== "createdAt" &&
            column.key !== "adminApproval"
        )
        .forEach((column) => {
          printWindow.document.write(`<td>${record[column.dataIndex]}</td>`);
        });
      printWindow.document.write("</tr>");
    });
  
    printWindow.document.write("</table>");
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  };
  

  const filterData = () => {
    if (searchQuery.trim() === "") {
      setFilteredData(null);
    } else {
      const filtered = data?.filter((item) => {
        return (
          item.venue.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.bookingFor
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          item.bookingStatus.toLowerCase().includes(searchQuery.toLowerCase()) 
         
        );
      });
      setFilteredData(filtered);
    }
  };

  useEffect(() => {
    filterData();
  }, [searchQuery]);

  const cancelModal = () => {
    setShowModal(false);
  };
  const handleEdit = (record) => {
    setSelectedRecord(record);
    setId(record.id);
    setShowModal(true);
  };

  const handleUpdate = async (values) => {
    try {
      setLoading(true);
      const data = {
        id,
        bookingStatus: values.bookingStatus,
      };
      console.log("Data before dispatch:", data);
      const res = await dispatch(updateBooking(data));
      if (res.success) {
        message.success(res.message);
        form.resetFields();
      }
    } catch (error) {
      console.log(error);
      message.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };



  return (
    <div>
   
      <div className="flex justify-between items-center pt-5 pl-4 pr-4">
        <h2 className="text-lg font-semibold text-gray-800">All Booking</h2>
        <Breadcrumb>
        <Breadcrumb.Item>
            <a href="/maintenance/dashboard">
             Home
            </a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>All Booking</Breadcrumb.Item>
        </Breadcrumb>
      
       
      </div>
      <div className="bg-white rounded-lg p-6 shadow-md m-5">
      <div style={{ marginTop: "30px" }}>
        <div className="button-container">
          <div className="mobile-buttons">
            <Button
              type="primary"
              className="mobile-button bg-blue-500 text-white"
              onClick={handlePrint}
            >
              <PrinterOutlined /> Print
            </Button>
          </div>

          <div className="mobile-search">
            <Input.Search
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>

        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <Table
            columns={columns}
            dataSource={filteredData || data}
            loading={loading}
            pagination={{
              pageSize: pageSize,
              current: currentPage,
              onChange: handlePageChange,
              total: totalPage * pageSize,
            }}
          />
        </div>
      </div>

      <Modal
        title="Print Table"
        open={isPrintModalVisible}
        onOk={printTable}
        onCancel={handlePrintModalCancel}
        okButtonProps={{className:'bg-blue-500 text-white'}}
      >
        <p>Review the table data below before printing:</p>
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <Table columns={columns} dataSource={printData} pagination={false} />
        </div>
      </Modal>

      <Modal
        title="Edit Booking"
        open={showModal}
        onCancel={cancelModal}
        footer={null}
      >
        <Form
          form={form}
          onFinish={handleUpdate}
          layout="vertical"
          initialValues={selectedRecord}
        >
          <Form.Item
            label="Booking Status"
            name="bookingStatus"
            rules={[
              {
                required: true,
                message: "Please select booking status",
              },
            ]}
          >
            <Select placeholder="Select Booking Status">
              <Option value="Open">Open</Option>
              <Option value="Close">Close</Option>
            </Select>
          </Form.Item>
          
          <Form.Item name="venue" label="Venue">
            <Input disabled />
          </Form.Item>

          <Form.Item label="Start Time" name="startTime">
            <Input disabled />
          </Form.Item>

          <Form.Item label="End Time" name="endTime">
            <Input disabled />
          </Form.Item>

          <Form.Item name="bookingFor" label="Booking For">
            <Input disabled />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading} className="bg-blue-500 text-white">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      </div>
    </div>
  );
};

export default Booking;
