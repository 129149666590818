import { GET_MAINTENANCE_TICKET,UPDATE_MAINTENANCE_TICKET} from "../../../constants/actionTypes";
import * as api from "../../../api";

export const getMaintanaceTicket = (params) => async (dispatch) => {
  
    try {
        const { data } = await api.getMaintenanceTicket(params);
        dispatch({ type: GET_MAINTENANCE_TICKET, payload: data });
        return data;
    } catch (error) {
        console.log(error);
        throw error;
    }
};


export const updateMaintenanceTicket = (ticket) => async (dispatch) => {
    try {
      const { data } = await api.updateMaintenanceTicket(ticket);
      dispatch({ type: UPDATE_MAINTENANCE_TICKET, payload: data });
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };