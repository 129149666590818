import { ADD_EMPLOYEE_BOOKING,GET_EMPLOYEE_BOOKING } from "../../../constants/actionTypes";
import * as api from "../../../api";


export const addEmployeeBooking = (booking) => async (dispatch) => {
    try {
        const { data } = await api.addEmployeeBooking(booking);
        dispatch({ type: ADD_EMPLOYEE_BOOKING, payload: data });
        return data;
    } catch (error) {
        console.log(error);
        throw error;
    }
};


export const getEmployeeBooking  = (params) => async (dispatch) => {
    try {
        const { data } = await api.getEmployeeBooking(params);
        // console.log(data)
        dispatch({ type: GET_EMPLOYEE_BOOKING, payload: data });
        return data;
    } catch (error) {
        console.log(error);
        throw error;
    }
};