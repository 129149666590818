import * as actionTypes from "../../../constants/actionTypes";

const initialState = {
  booking: [],
  state: "idle",
  error: null,
};

export const employeeBookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_EMPLOYEE_BOOKING:
      return {
        ...state,
        booking: action.payload.booking
      };
      case actionTypes.GET_EMPLOYEE_BOOKING:
        return {
          ...state,
          booking: action.payload
        };

    default:
      return state;
  }
};

export default employeeBookingReducer;
