import * as actionTypes from "../../../constants/actionTypes";

const initialState = {
  booking: [],
  state: "idle",
  success: null,
  error: null,
};

export const maintenanceBookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_MAINTENANCE_BOOKING:
      return {
        ...state,
        booking: action.payload,
      };
    case actionTypes.UPDATE_BOOKING:
      return {
        ...state,
        success: action.payload,
        error: null,
      };
    default:
      return state;
  }
};

export default maintenanceBookingReducer;
