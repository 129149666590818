import * as actionTypes from "../../../constants/actionTypes";

const initialState = {
  ticketNumber: [],
  openTicket: [],
  closeTicket: [],
  state: "idle",
  error: null,
};

export const maintenanceDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TICKET_NUMBER:
      return {
        ...state,
        ticketNumber: action.payload,
      };
    case actionTypes.GET_OPEN_TICKET:
      return {
        ...state,
        openTicket: action.payload,
      };
    case actionTypes.GET_CLOSE_TICKET:
      return {
        ...state,
        closeTicket: action.payload,
      };
  
    default:
      return state;
  }
};

export default maintenanceDashboardReducer;
