import { GET_MAINTENANCE_BOOKING, UPDATE_BOOKING } from "../../../constants/actionTypes";
import * as api from "../../../api";

export const getMaintenanceBooking  = (params) => async (dispatch) => {
    try {
        const { data } = await api.getMaintenanceBooking(params);
        // console.log(data)
        dispatch({ type: GET_MAINTENANCE_BOOKING, payload: data });
        return data;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

export const updateBooking = (booking) => async (dispatch) => {
    try {
      const { data } = await api.updateBooking(booking);
      dispatch({ type: UPDATE_BOOKING, payload: data });
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };