import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  Button,
  Table,
  Input,
  Modal,
  Space,
  Form,
  Select,
  message,
  Tag
} from "antd";
import {
  DownloadOutlined,
  PrinterOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import {
  getMaintanaceTicket,
  updateMaintenanceTicket,
} from "../../../actions/maintenance/ticket/ticket";

const { Option } = Select;

const Ticket = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [printData, setPrintData] = useState([]);
  const [isPrintModalVisible, setIsPrintModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [ticketCategory, setTicketCategory] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [totalPage, setTotalPage] = useState("");
  const [selectedRecord, setSelectedRecord] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showSubCategory, setShowSubCategory] = useState(false);
  const [showIssue, setShowIssue] = useState(false);
  const [id, setId] = useState("");

  const columns = [
    {
      title: "SNo",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    //   {
    //     title: "Image",
    //     dataIndex: "attachment",
    //     key: "avatar",
    //     render: (attachments) => (
    //       <div>
    //         {Array.isArray(attachments) &&
    //           attachments.map((attachment, index) => (
    //             <div key={index}>
    //               <img
    //                 src={attachment.attachment_Path}
    //                 alt={attachment.attachment_OriginalName}
    //                 style={{height:'60px',width:'100px'}}
    //               />
    //             </div>
    //           ))}
    //       </div>
    //     ),
    //   },
    {
      title: "Ticket Number",
      dataIndex: "ticketNumber",
      key: "ticketNumber",
      // render: (text, record) => (
      //   <Link
      //     to={`/employee/tickets/${record.id}/timeline`}
      //     style={{ color: "black", cursor: "pointer" }}
      //   >
      //     {text}
      //   </Link>
      // ),
    },
    {
      title: "Ticket Category",
      dataIndex: "ticketCategory",
      key: "ticketCategory",
    },
    {
      title: "Maintenance",
      dataIndex: "maintenance_security",
      key: "maintenance_security",
      render:(text)=>text? `${text}`:"-"
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
    },
    {
      title: "Reply",
      dataIndex: "reply",
      key: "reply",
      render:(text)=>text? `${text}`:"-"
    },
    
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        let color;
        switch (status) {
          case "CREATED":
            color = "blue";
            break;
          case "ONGOING":
            color = "yellow";
            break;
          case "RESOLVED":
            color = "green";
            break;
          default:
            color = "volcano";
            break;
        }

        return <Tag color={color}>{status.toUpperCase()}</Tag>;
      },
    },

    {
      title: "Detail",
      dataIndex: "details",
      key: "details",
    },
  ];

  const columnsWithAction = [
    ...columns,
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <EditOutlined
            style={{ fontSize: "16px" }}
            onClick={() => handleEdit(record)}
          />
        </Space>
      ),
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const params = {
          recordLimit: pageSize,
          page: currentPage,
        };
        const result = await dispatch(getMaintanaceTicket(params));
        setData(result.data);
        setTotalPage(result.totalPage);
      } catch (error) {
        console.error("Error fetching tickets:", error);
        message.error(error.response.data.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, pageSize, currentPage]);

  const csvData = [
    ["Ticket Number", "Ticket Category", "Subject", "Detail", "Status"],
    ...data?.map((item) => [
      item.ticketNumber,
      item.ticketCategory,
      item.subject,
      item.details,
      item.status,
    ]),
  ];

  const arrayToCSV = (arr) => {
    return arr
      .map((row) => row.map((cell) => `"${cell}"`).join(","))
      .join("\n");
  };

  const downloadCSV = () => {
    const csvString = arrayToCSV(csvData);
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "myticket.csv";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  const handlePrint = () => {
    setPrintData(data);
    setIsPrintModalVisible(true);
  };
  // console.log(printData);

  const handlePrintModalCancel = () => {
    setIsPrintModalVisible(false);
  };

  const printTable = () => {
    const printWindow = window.open("", "_blank");
    printWindow.document.open();
    printWindow.document.write("<html><head><title>Print</title>");

    // Add custom CSS styles here
    printWindow.document.write(`
      <style>
        table {
          width: 100%; 
        }
        th, td {
          padding: 10px;
          border: 1px solid #000; 
        }
      </style>
    `);

    printWindow.document.write("</head><body>");
    printWindow.document.write(
      '<h1 style="text-align: center;">Printed Table</h1>'
    );
    printWindow.document.write('<table border="1" style="margin: 0 auto;">');
    printWindow.document.write("<tr>");

    columns
      .filter((column) => column.key !== "action" && column.key !== "avatar")
      .forEach((column) => {
        printWindow.document.write(`<th>${column.title}</th>`);
      });
    printWindow.document.write("</tr>");
    printData.forEach((record) => {
      printWindow.document.write("<tr>");

      columns
        .filter((column) => column.key !== "action" && column.key !== "avatar")
        .forEach((column) => {
          printWindow.document.write(`<td>${record[column.dataIndex]}</td>`);
        });
      printWindow.document.write("</tr>");
    });

    printWindow.document.write("</table>");
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  };

  const filterData = () => {
    if (searchQuery.trim() === "") {
      setFilteredData(null);
    } else {
      const filtered = data?.filter((item) => {
        return (
          item.details.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.ticketCategory
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          item.ticketNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.subject.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.status.toLowerCase().includes(searchQuery.toLowerCase())
        );
      });
      setFilteredData(filtered);
    }
  };

  useEffect(() => {
    filterData();
  }, [searchQuery]);

  const handleEdit = (record) => {
    setSelectedRecord(record);
    setId(record.id);
    setShowModal(true);
    setShowSubCategory(record.ticketCategory === "Maintenance");
    setShowIssue(record.ticketCategory === "SecurityRelatedIssue");
  };

  const cancelModal = () => {
    setShowModal(false);
  };

  const handleCategory = (value) => {
    setTicketCategory(value);
    if (value === "Maintenance") {
      setShowSubCategory(true);
    } else {
      setShowSubCategory(false);
    }
    if (value === "Security Related") {
      setShowIssue(true);
    } else {
      setShowIssue(false);
    }
  };

  const handleUpdate = async (values) => {
    try {
      setLoading(true);
      const data = {
        id,
        ...values,
      };
      console.log("Data before dispatch:", data);
      const res = await dispatch(updateMaintenanceTicket(data));
      if (res.success) {
        message.success(res.message);
        form.resetFields();
      }
    } catch (error) {
      console.log(error);
      message.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  return (
    <div>
  
      <div className="flex justify-between items-center pt-5 pl-4 pr-4">
        <h2 className="text-lg font-semibold text-gray-800">Ticket</h2>
        <Breadcrumb>
        <Breadcrumb.Item>
            <a href="/maintenance/dashboard">
             Home
            </a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Ticket</Breadcrumb.Item>
        </Breadcrumb>
      
       
      </div>
      <div className="bg-white rounded-lg p-6 shadow-md m-5">
      <div style={{ marginTop: "20px" }}>
        <div className="button-container">
          <div className="mobile-buttons">
            <Button
              type="primary"
              className="mobile-button bg-blue-500 text-white"
              onClick={downloadCSV}
            >
              <DownloadOutlined /> Export
            </Button>
            <Button
              type="primary"
              className="mobile-button bg-blue-500 text-white"
              onClick={handlePrint}
            >
              <PrinterOutlined /> Print
            </Button>
          </div>

          <div className="mobile-search">
            <Input.Search
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>

        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <Table
            columns={columnsWithAction}
            dataSource={filteredData || data}
            loading={loading}
            pagination={{
              pageSize: pageSize,
              current: currentPage,
              onChange: handlePageChange,
              total: totalPage * pageSize,
            }}
          />
        </div>
      </div>

      <Modal
        title="Print Table"
        open={isPrintModalVisible}
        onOk={printTable}
        onCancel={handlePrintModalCancel}
        okButtonProps={{className:"bg-blue-500 text-white"}}
      >
        <p>Review the table data below before printing:</p>
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <Table columns={columns} dataSource={printData} pagination={false} />
        </div>
      </Modal>

      <Modal
        title="Edit Ticket"
        open={showModal}
        onCancel={cancelModal}
        footer={null}
      >
        <Form
          form={form}
          onFinish={handleUpdate}
          layout="vertical"
          initialValues={selectedRecord}
        >
          <Form.Item
            label="Status"
            name="status"
            rules={[
              {
                required: true,
                message: "Please select status",
              },
            ]}
          >
            <Select placeholder="Select Status">
              <Option value="CREATED">Created</Option>
              <Option value="ONGOING">Ongoing</Option>
              <Option value="RESOLVED">Resolved</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Subject"
            name="subject"
            rules={[
              {
                required: true,
                message: "Please Enter subject",
              },
            ]}
          >
            <Input placeholder="Subject" />
          </Form.Item>

          <Form.Item
            label="Ticket Category"
            name="ticketCategory"
            rules={[
              {
                required: true,
                message: "Please select ticket category",
              },
            ]}
          >
            <Select
              placeholder="Select Category"
              value={ticketCategory}
              onChange={handleCategory}
            >
              <Option value="Maintenance">Maintenance</Option>
              <Option value="Security Related">
                Security related issue
              </Option>
            </Select>
          </Form.Item>

          {showSubCategory && (
            <Form.Item
              name="maintenance_security"
              label="Maintenance Type"
              rules={[
                { required: true, message: "Please select maintenance type" },
              ]}
            >
              <Select placeholder="Select Maintenance Type">
                <Option value="Electric">Electric</Option>
                <Option value="Plumbing">Plumbing</Option>
                <Option value="House Keeping">House Keeping</Option>
                <Option value="Wood work">Wood work</Option>
                <Option value="AC">AC</Option>
                <Option value="Audio & Video">Audio & Video</Option>
              </Select>
            </Form.Item>
          )}
          {showIssue && (
            <Form.Item
              name="maintenance_security"
              label="Security Type"
              rules={[
                { required: true, message: "Please select security type" },
              ]}
            >
              <Select placeholder="Select Security Type">
                <Option value="LockUnlock">Lock Unlock</Option>
                <Option value="ParkingIssue">Parking Issue</Option>
              </Select>
            </Form.Item>
          )}
          <Form.Item name="reply" label="Reply">
            <Input.TextArea rows={3} placeholder="Enter Reply" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading} className="bg-blue-500 text-white">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
    </div>
  );
};

export default Ticket;
