import React from 'react';
import { Card } from 'antd';
import './SkeletonLoader.css';

const SkeletonLoader = () => {
  return (
    <div className="skeleton-container">
      <Card style={{ width: 400 }}>
        <div className="skeleton-loader">
          <div className="skeleton title"></div>
          <div className="skeleton input"></div>
          <div className="skeleton input"></div>
          <div className="skeleton button"></div>
        </div>
      </Card>
    </div>
  );
};

export default SkeletonLoader;
