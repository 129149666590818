import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  Button,
  Space,
  Table,
  Input,
  Modal,
  Form,
  message,
} from "antd";
import {
  EditOutlined,
  PlusOutlined,
  DownloadOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import "../items/Items.css";
import {
  addAssetsCategoryStore,
  getAssetsCategoryStore,
  getStoreAssetsByCategory,
  updateStoreAssetCategory,
} from "../../actions/storeKeeper/assetsCategory/assetsCategory";
import { addAssets } from "../../actions/storeKeeper/assets/assets";

const Category = () => {
  const dispatch = useDispatch();
  // const assets = useSelector((state) => state.assetsCategoryStore.category);
  const [data, setData] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [successMsg, setSuccessMsg] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [printData, setPrintData] = useState([]);
  const [isPrintModalVisible, setIsPrintModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [category, setCategory] = useState("");
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalPage, setTotalPage] = useState("");
  const [id, setId] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categoryAssets, setCategoryAssets] = useState([]);
  const [categoryModal, setCategoryModal] = useState(false);
  const [isItemAdded, setIsItemAdded] = useState(false);
  const [form] = Form.useForm();

  const columns = [
    {
      title: "SNo",
      dataIndex: "sno",
      key: "sno",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Category name",
      dataIndex: "categoryName",
      key: "categoryName",
      render: (text, record) => (
        <a onClick={() => handleCategoryClick(record.categoryName)}>{text}</a>
      ),
    },
    {
      title: "Category number",
      dataIndex: "categoryNumber",
      key: "categoryNumber",
    },

    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <EditOutlined
            style={{ fontSize: "16px" }}
            onClick={() => handleEditClick(record.id)}
          />
          {/* <DeleteOutlined style={{ color: "red", fontSize: "16px" }} /> */}
        </Space>
      ),
    },
  ];

  const assetsColumn = [
    {
      title: "SNo",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Asset Name",
      dataIndex: "itemName",
      key: "itemName",
    },
    {
      title: "Asset Serial Number",
      dataIndex: "itemSerialNumber",
      key: "itemSerialNumber",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
  ];

  const handleCategoryClick = (categoryName) => {
    setSelectedCategory(categoryName);
    setCategoryModal(true);
  };

  const handleSave = async () => {
    try {
      setLoading1(true);
      const data = {
        categoryName,
      };
      const res = await dispatch(addAssetsCategoryStore(data));
      if (res.success) {
        message.success(res.message);
        setSuccessMsg(true);
        setIsModalVisible(false);
        form.resetFields();
        setTimeout(() => {
          setSuccessMsg(false);
        }, 2000);
      }
    } catch (error) {
      console.log(error);
      message.error(error.response.data.message);
    } finally {
      setLoading1(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const params = {
          page: currentPage,
          recordLimit: pageSize,
        };
        const result = await dispatch(getAssetsCategoryStore(params));
        setData(result.data);
        setTotalPage(result.totalPage);
      } catch (error) {
        console.error("Error fetching category:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, currentPage, pageSize]);

  useEffect(() => {
    if (selectedCategory) {
      const fetchData = async () => {
        try {
          setLoading(true);
          const params = { assetCategory: selectedCategory };
          const result = await dispatch(getStoreAssetsByCategory(params));
          console.log(result);
          setCategoryAssets(result.data);
        } catch (error) {
          console.error("Error fetching assets:", error);
          message.error(
            error?.response?.data?.message ||
              "This category assets is not present!"
          );
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [selectedCategory, dispatch]);

  const csvData = [
    ["Category Name", "Category Number"],
    ...data?.map((item) => [item.categoryName, item.categoryNumber]),
  ];

  const arrayToCSV = (arr) => {
    return arr
      .map((row) => row.map((cell) => `"${cell}"`).join(","))
      .join("\n");
  };

  const downloadCSV = () => {
    const csvString = arrayToCSV(csvData);
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "category.csv";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  const handleAddCategoryClick = () => {
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handlePrint = () => {
    setPrintData(data);
    setIsPrintModalVisible(true);
  };

  const handlePrintModalCancel = () => {
    setIsPrintModalVisible(false);
  };

  const printTable = () => {
    const printWindow = window.open("", "_blank");
    printWindow.document.open();
    printWindow.document.write("<html><head><title>Print</title>");

    // Add custom CSS styles here
    printWindow.document.write(`
      <style>
        table {
          width: 100%; 
        }
        th, td {
          padding: 10px;
          border: 1px solid #000; 
        }
      </style>
    `);

    printWindow.document.write("</head><body>");
    printWindow.document.write(
      '<h1 style="text-align: center;">Printed Table</h1>'
    );
    printWindow.document.write('<table border="1" style="margin: 0 auto;">');
    printWindow.document.write("<tr>");

    columns
      .filter((column) => column.key !== "action")
      .forEach((column) => {
        printWindow.document.write(`<th>${column.title}</th>`);
      });
    printWindow.document.write("</tr>");
    printData.forEach((record) => {
      printWindow.document.write("<tr>");

      columns
        .filter((column) => column.key !== "action")
        .forEach((column) => {
          printWindow.document.write(`<td>${record[column.dataIndex]}</td>`);
        });
      printWindow.document.write("</tr>");
    });

    printWindow.document.write("</table>");
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  };

  const filterData = () => {
    if (searchQuery.trim() === "") {
      setFilteredData(null);
    } else {
      const filtered = data?.filter((item) => {
        return (
          item.categoryName.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.categoryNumber.toLowerCase().includes(searchQuery.toLowerCase())
        );
      });
      setFilteredData(filtered);
    }
  };

  useEffect(() => {
    filterData();
  }, [searchQuery]);

  const handleEditClick = (itemId) => {
    setId(itemId);
    setIsEditModalVisible(true);
    const selectedItem = data.find((item) => item.id === itemId);
    if (selectedItem) {
      // console.log(selectedItem)
      setCategory(selectedItem.categoryName);
    }
  };

  const handleEditSave = async () => {
    try {
      const data = {
        id,
        categoryName: category,
      };
      const res = await dispatch(updateStoreAssetCategory(data));
      if (res.success) {
        message.success(res.message);
        setIsEditModalVisible(false);
        setCategory("");
        setId(null);
      }
    } catch (error) {
      console.error("Error during validation or dispatch:", error);
      message.error(error.response.data.message);
    }
  };

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const handleAddAsset = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
     const data={
      assetCategory:selectedCategory,
      ...values
     }
      const res = await dispatch(addAssets(data));
      if (res.success) {
        message.success(res.message);
        setIsItemAdded(false);
        form.resetFields();
      }
    } catch (error) {
      console.error("Error during validation or dispatch:", error);
      message.error(error?.response?.data?.message || "An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setIsItemAdded(false);
    form.resetFields();
  };

  const handleOpen=()=>{
    setIsItemAdded(true);
    setCategoryModal(false);
  }
 

  return (
    <div>
      <div className="flex justify-between items-center pt-5 pl-4 pr-4">
        <h2 className="text-lg font-semibold text-gray-800">Manage Category</h2>
        <Breadcrumb>
          <Breadcrumb.Item>
            <a href="/storeKeeper/dashboard">Home</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Category</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="bg-white rounded-lg p-6 shadow-md m-5">
        {successMsg && (
          <div style={{ color: "green" }}>
            Assets category added successfully
          </div>
        )}
        <Button
          type="primary"
          className="bg-blue-500 text-white"
          icon={<PlusOutlined />}
          onClick={handleAddCategoryClick}
        >
          Add Category
        </Button>
        <div style={{ marginTop: "30px" }}>
          <div className="button-container">
            <div className="mobile-buttons">
              <Button
                type="primary"
                className="mobile-button bg-blue-500 text-white"
                onClick={downloadCSV}
              >
                <DownloadOutlined /> CSV
              </Button>
              <Button
                type="primary"
                className="mobile-button bg-blue-500 text-white"
                onClick={handlePrint}
              >
                <PrinterOutlined /> Print
              </Button>
            </div>

            <div className="mobile-search">
              <Input.Search
                placeholder="Search..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          </div>

          <Table
            columns={columns}
            dataSource={filteredData || data}
            loading={loading}
            pagination={{
              pageSize: pageSize,
              current: currentPage,
              onChange: handlePageChange,
              total: totalPage * pageSize,
            }}
          />
        </div>
        <Modal
          title="Add Category"
          visible={isModalVisible}
          onOk={handleSave}
          onCancel={handleModalCancel}
          cancelText="Close"
          okText="Save Changes"
          loading={loading1}
          okButtonProps={{ className: "bg-blue-500 text-white" }}
        >
          <Form form={form} layout="vertical">
            <Form.Item
              name="categoryName"
              label="Category Name"
              rules={[
                {
                  required: true,
                  message: "Please enter the category name",
                },
              ]}
              style={{ marginBottom: "12px" }}
            >
              <Input
                placeholder="Enter category name"
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
              />
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="Print Table"
          visible={isPrintModalVisible}
          onOk={printTable}
          onCancel={handlePrintModalCancel}
          okButtonProps={{ className: "bg-blue-500 text-white" }}
        >
          <p>Review the table data below before printing:</p>
          <Table columns={columns} dataSource={printData} pagination={false} />
        </Modal>

        <Modal
          title={`${selectedCategory} Assets`}
          open={categoryModal}
          onOk={() => setCategoryModal(false)}
          onCancel={() => setCategoryModal(false)}
          okButtonProps={{ className: "bg-blue-500 text-white" }}
        >
          <div style={{ marginBottom: "16px" }}>
            <Button
              type="primary"
              className="bg-blue-500 text-white"
              onClick={handleOpen}
            >
              Add Asset
            </Button>
          </div>
          <Table
            columns={assetsColumn}
            dataSource={categoryAssets}
            pagination={false}
          />
        </Modal>

        <Modal
          title="Edit Item"
          visible={isEditModalVisible}
          onCancel={() => {
            setIsEditModalVisible(false);
          }}
          onOk={handleEditSave}
          okText="Update"
          cancelText="Close"
          okButtonProps={{ className: "bg-blue-500 text-white" }}
        >
          <Form layout="vertical">
            <Form.Item
              label="Category Name"
              rules={[
                {
                  required: true,
                  message: "Please enter the category name",
                },
              ]}
            >
              <Input
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              />
            </Form.Item>
          </Form>
        </Modal>

        <Modal
        title={"Add Items"}
        visible={isItemAdded}
        onOk={handleAddAsset}
        onCancel={handleCancel}
        cancelText="Close"
        okText="Save Changes"
        okButtonProps={{className:"bg-blue-500 text-white"}}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="itemName"
            label="Item Name"
            rules={[
              {
                required: true,
                message: "Please enter the item name",
              },
            ]}
            style={{ marginBottom: "12px" }}
          >
            <Input placeholder="Enter item name" />
          </Form.Item>
        
          <Form.Item
            name="itemSerialNumber"
            label="Item Serial Number"
            rules={[
              {
                required: true,
                message: "Please enter item serial number",
              },
            ]}
          >
            <Input placeholder="Item Serial Number" type="number" />
          </Form.Item>
          <Form.Item
            name="quantity"
            label="Quantity"
            rules={[
              {
                required: true,
                message: "Please enter quantity",
              },
            ]}
          >
            <Input placeholder="Quantity" type="number" />
          </Form.Item>
        </Form>
      </Modal>
      </div>
    </div>
  );
};

export default Category;
