import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  Button,
  Space,
  Table,
  Input,
  Modal,
  Form,
  Select,
  message,
} from "antd";
import {
  EditOutlined,
  PlusOutlined,
  DeleteOutlined,
  DownloadOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { getAssetsCategoryStore } from "../../actions/storeKeeper/assetsCategory/assetsCategory";
import "./Items.css";
import {
  addAssets,
  getAssets,
  getAssetsById,
  updateAssets,
} from "../../actions/storeKeeper/assets/assets";

const { Option } = Select;

const Items = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();
  const [data, setData] = useState([]);
  const [id, setId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [printData, setPrintData] = useState([]);
  const [assetsData, setAssetsData] = useState([]);
  const [pageSize, setPageSize] = useState(25);
  const [totalPage, setTotalPage] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredData, setFilteredData] = useState(null);
  const [editLoading, setEditLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isPrintModalVisible, setIsPrintModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  const columns = [
    {
      title: "Item Name",
      dataIndex: "itemName",
      key: "itemName",
    },
    {
      title: "Assets Category",
      dataIndex: "assetCategory",
      key: "assetCategory",
    },
    {
      title: "Item Serial Number",
      dataIndex: "itemSerialNumber",
      key: "itemSerialNumber",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <EditOutlined
            style={{ fontSize: "16px" }}
            onClick={() => handleEditClick(record.id)}
          />
          {/* <DeleteOutlined style={{ color: "red", fontSize: "16px" }} /> */}
        </Space>
      ),
    },
  ];
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await dispatch(getAssetsCategoryStore());
        setAssetsData(result.data);
      } catch (error) {
        console.error("Error fetching tickets:", error);
        message.error(
          error?.response?.data?.message || "Something went wrong!"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const params = {
          page: currentPage,
          recordLimit: pageSize,
        };
        const result = await dispatch(getAssets(params));
        setData(result.data);
        setTotalPage(result.totalPage);
      } catch (error) {
        console.error("Error fetching assets:", error);
        message.error(
          error?.response?.data?.message || "Something went wrong!"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, currentPage, pageSize]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await dispatch(getAssetsById(id));
        editForm.setFieldValue({
          itemName: result.data.itemName,
          assetCategory: result.data.assetCategory,
          quantity: result.data.quantity,
          itemSerialNumber: result.data.itemSerialNumber,
        });
      } catch (error) {
        console.error("Error fetching item:", error);
        message.error(
          error?.response?.data?.message || "Something went wrong!"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, id, editForm]);

  const csvData = [
    ["Item Name", "Assets Category", "Quantity", "Item Serial Number"],
    ...data?.map((item) => [
      item.itemName,
      item.assetCategory,
      item.quantity,
      item.itemSerialNumber,
    ]),
  ];

  const arrayToCSV = (arr) => {
    return arr
      .map((row) => row.map((cell) => `"${cell}"`).join(","))
      .join("\n");
  };

  const downloadCSV = () => {
    const csvString = arrayToCSV(csvData);
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "items.csv";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  const handleAddItemsClick = () => {
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      // console.log("Received values:", values);
      const res = await dispatch(addAssets(values));
      if (res.success) {
        message.success(res.message);
        setIsModalVisible(false);
        form.resetFields();
      }
    } catch (error) {
      console.error("Error during validation or dispatch:", error);
      message.error(error?.response?.data?.message || "Something went wrong!");
    } finally {
      setLoading(false);
    }
  };

  const handleEditClick = (itemId) => {
    setId(itemId);
    setIsEditModalVisible(true);
    const selectedItem = data.find((item) => item.id === itemId);
    // console.log(selectedItem);
    if (selectedItem) {
      editForm.setFieldsValue(selectedItem);
    }
  };

  const handlePrint = () => {
    setPrintData(data);
    setIsPrintModalVisible(true);
  };

  const handlePrintModalCancel = () => {
    setIsPrintModalVisible(false);
  };

  const printTable = () => {
    const printWindow = window.open("", "_blank");
    printWindow.document.open();
    printWindow.document.write("<html><head><title>Print</title>");

    // Add custom CSS styles here
    printWindow.document.write(`
      <style>
        table {
          width: 100%; 
        }
        th, td {
          padding: 10px;
          border: 1px solid #000; 
        }
      </style>
    `);

    printWindow.document.write("</head><body>");
    printWindow.document.write(
      '<h1 style="text-align: center;">Printed Table</h1>'
    );
    printWindow.document.write('<table border="1" style="margin: 0 auto;">');
    printWindow.document.write("<tr>");

    columns
      .filter((column) => column.key !== "action")
      .forEach((column) => {
        printWindow.document.write(`<th>${column.title}</th>`);
      });
    printWindow.document.write("</tr>");
    printData.forEach((record) => {
      printWindow.document.write("<tr>");

      columns
        .filter((column) => column.key !== "action")
        .forEach((column) => {
          printWindow.document.write(`<td>${record[column.dataIndex]}</td>`);
        });
      printWindow.document.write("</tr>");
    });

    printWindow.document.write("</table>");
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  };

  const handleEditSave = async (values) => {
    // console.log(values)
    try {
      const data = {
        id,
        ...values,
      };
      // console.log(data)
      setEditLoading(true);
      const res = await dispatch(updateAssets(data));
      if (res.success) {
        message.success(res.message);
        editForm.resetFields();
        setIsEditModalVisible(false);
      }
    } catch (error) {
      console.error("Error during validation or dispatch:", error);
      message.error(error?.response?.data?.message || "Something went wrong!");
    } finally {
      setEditLoading(false);
    }
  };

  const filterData = () => {
    if (searchQuery.trim() === "") {
      setFilteredData(null);
    } else {
      const filtered = data?.filter((item) => {
        return (
          item.itemName.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.assetCategory.toLowerCase().includes(searchQuery.toLowerCase())
        );
      });
      setFilteredData(filtered);
    }
  };

  useEffect(() => {
    filterData();
  }, [searchQuery]);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  return (
    <div>
      <div className="flex justify-between items-center pt-5 pl-4 pr-4">
        <h2 className="text-lg font-semibold text-gray-800">Manage Items</h2>
        <Breadcrumb>
          <Breadcrumb.Item>
            <a href="/storeKeeper/dashboard">Home</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Items</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="bg-white rounded-lg p-6 shadow-md m-5">
        <Button
          type="primary"
          className="bg-blue-500 text-white"
          icon={<PlusOutlined />}
          onClick={handleAddItemsClick}
        >
          Add Items
        </Button>
        <div style={{ marginTop: "20px" }}>
          <div className="button-container">
            <div className="mobile-buttons">
              <Button
                type="primary"
                className="mobile-button bg-blue-500 text-white"
                onClick={downloadCSV}
              >
                <DownloadOutlined /> CSV
              </Button>
              <Button
                type="primary"
                className="mobile-button bg-blue-500 text-white"
                onClick={handlePrint}
              >
                <PrinterOutlined /> Print
              </Button>
            </div>

            <div className="mobile-search">
              <Input.Search
                placeholder="Search..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          </div>

          <Table
            columns={columns}
            dataSource={filteredData || data}
            loading={loading}
            pagination={{
              pageSize: pageSize,
              current: currentPage,
              onChange: handlePageChange,
              total: totalPage * pageSize,
            }}
          />
        </div>
        <Modal
          title={"Add Items"}
          visible={isModalVisible}
          onOk={handleSave}
          onCancel={handleModalCancel}
          cancelText="Close"
          okText="Save Changes"
          okButtonProps={{ className: "bg-blue-500 text-white" }}
        >
          <Form form={form} layout="vertical">
            <Form.Item
              name="itemName"
              label="Item Name"
              rules={[
                {
                  required: true,
                  message: "Please enter the item name",
                },
              ]}
              style={{ marginBottom: "12px" }}
            >
              <Input placeholder="Enter item name" />
            </Form.Item>
            <Form.Item
              label="Assets Category"
              name="assetCategory"
              rules={[
                { required: true, message: "Please select assets category!" },
              ]}
            >
              <Select placeholder="Assets Category">
                {assetsData?.map((data, index) => (
                  <Option key={index} value={data.categoryName}>
                    {data.categoryName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="itemSerialNumber"
              label="Item Serial Number"
              rules={[
                {
                  required: true,
                  message: "Please enter item serial number",
                },
              ]}
            >
              <Input placeholder="Item Serial Number" type="number" />
            </Form.Item>
            <Form.Item
              name="quantity"
              label="Quantity"
              rules={[
                {
                  required: true,
                  message: "Please enter quantity",
                },
              ]}
            >
              <Input placeholder="Quantity" type="number" />
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="Print Table"
          visible={isPrintModalVisible}
          onOk={printTable}
          onCancel={handlePrintModalCancel}
          okButtonProps={{ className: "bg-blue-500 text-white" }}
        >
          <p>Review the table data below before printing:</p>
          <Table columns={columns} dataSource={printData} pagination={false} />
        </Modal>
        <Modal
          title="Edit Item"
          visible={isEditModalVisible}
          onCancel={() => {
            setIsEditModalVisible(false);
          }}
          footer={null}
        >
          <Form layout="vertical" form={editForm} onFinish={handleEditSave}>
            <Form.Item
              name="itemName"
              label="Item Name"
              rules={[
                {
                  required: true,
                  message: "Please enter the item name",
                },
              ]}
            >
              <Input placeholder="Item Name" />
            </Form.Item>
            <Form.Item
              name="assetCategory"
              label="Assets Category"
              rules={[
                { required: true, message: "Please select assets category!" },
              ]}
            >
              <Select placeholder="Select Assets Category">
                {assetsData?.map((data, index) => (
                  <Option key={index} value={data.categoryName}>
                    {data.categoryName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="quantity"
              label="Quantity"
              rules={[
                {
                  required: true,
                  message: "Please enter quantity",
                },
              ]}
            >
              <Input type="number" placeholder="Quantity" />
            </Form.Item>
            {/* <Form.Item
            name="itemSerialNumber"
            label="Item Serial Number"
            rules={[
              {
                required: true,
                message: "Please enter item serial number",
              },
            ]}
          >
            <Input placeholder="Item Serial Number" />
          </Form.Item> */}
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={editLoading}
                className="bg-blue-500 text-white"
              >
                Update
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </div>
  );
};

export default Items;
