import React, { useState } from "react";
import { Card, Form, Input, Button, message } from "antd";
import { useNavigate,useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { generatePassword } from "../../actions/forgetPassword/forgetPassword";

const GeneratePassword = () => {
    const location = useLocation();
    const email = location.state?.email;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const validatePassword = (_, value) => {
    if (value && value.length < 8) {
      return Promise.reject("Password must be at least 8 characters long");
    }
    const passwordField = form.getFieldValue("password");
    if (value && value !== passwordField) {
      return Promise.reject("Passwords do not match");
    }
  
    return Promise.resolve();
  }

//   console.log(email)
  const onFinish = async (values) => {
    try {
    //   console.log("Received values:", values);
      if (!email) {
        return;
      }
      setLoading(true);
      const data={
        email,
        password:values.password,
        confirmPassword:values.confirmPassword
      }
      const res = await dispatch(generatePassword(data));

      if (res.success) {
        message.success(res.message);
        const profileData = {
            authToken: res.authToken,
          };
        
          localStorage.setItem("profile", JSON.stringify(profileData));
        if (res.post === 'ADMIN') {
            navigate('/admin/dashboard');
          } else if (res.post === 'EMPLOYEE') {
            navigate('/employee/dashboard');
          } else if (res.post === 'STORE KEEPER') {
            navigate('/storeKeeper/dashboard');
          } else if (res.post === 'IT TECHNICIAN') {
            navigate('/ITTechnician/dashboard');
          } else {
            navigate('/');
          }

      } else {
        message.error(res.message);
      }
    } catch (error) {
      console.error("An error occurred during sign-in:", error);
      message.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <Card title="Create New Password" style={{ width: 400 }}>
        <Form
          form={form}
          name="generate-password"
          onFinish={onFinish}
          initialValues={{ remember: true }}
        >
        

          <Form.Item
            name="password"
            rules={[
              { required: true, message: "Please enter your password" },
              {
                validator: validatePassword,
              },
            ]}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            rules={[
              { required: true, message: "Please confirm your password" },
              {
                validator: validatePassword,
              },
            ]}
          >
            <Input.Password placeholder="Confirm Password" />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: "100%" }}
              loading={loading}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default GeneratePassword;
