import React, { useState } from "react";
import {
  Breadcrumb,
  Button,
  Input,
  Form,
  Select,
  Row,
  Col,
  Space,
  message,
} from "antd";
import {  UploadOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { addMyTicket } from "../../../actions/employee/myTicket/myTicket";

const { Option } = Select;

const Create = () => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [ticketCategory, setTicketCategory] = useState("");
  const [successMsg, setSuccessMsg] = useState(false);
  const [subCategory, setSubCategory] = useState("");
  const [showSubCategory, setShowSubCategory] = useState(false);
  const [showIssue, setShowIssue] = useState(false);

  const fileInputRef = React.createRef();

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    if (files.length < 1) {
      message.error("Please select at least 1 file.");
      return;
    }

    if (files.length > 10) {
      message.error("Please select  10 files.");
      return;
    }

    const fileList = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      fileList.push(file);
    }
    // console.log(fileList);
    setSelectedFiles(fileList);
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("subject", values.subject);
      formData.append("details", values.details);
      formData.append("ticketCategory", ticketCategory);
      selectedFiles.forEach((file) => {
        formData.append(`attachment`, file);
      });
      formData.append("maintenance_security",values.maintenance_security)

      const res = await dispatch(addMyTicket(formData));
      if (res.success) {
        message.success(res.message);
        setSuccessMsg(true);
        form.resetFields();
        setSelectedFiles([]);
        setTimeout(() => {
          setSuccessMsg(false);
        }, 2000);
      }
    } catch (error) {
      console.error("Error adding ticket:", error);

      message.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCategory = (value) => {
    setTicketCategory(value);
    if (value === "Maintenance") {
      setShowSubCategory(true);
    } else {
      setShowSubCategory(false);
    }
    if (value === "Security Related") {
      setShowIssue(true);
    } else {
      setShowIssue(false);
    }
  };

  const handleSubCategory = (value) => {
    setSubCategory(value);
  };

  return (
    <div>
   
  
      <div className="flex justify-between items-center pt-5 pl-4 pr-4">
    <h2 className="text-lg font-semibold text-gray-800">
      Create
    </h2>
    <Breadcrumb>
      <Breadcrumb.Item>
        {" "}
        <a href="/employee/dashboard">Home</a>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Create</Breadcrumb.Item>
    </Breadcrumb>
  </div>
  <div className="bg-white rounded-lg p-6 shadow-md m-5">
  {successMsg && (
        <div style={{ color: "green" }}>Ticket added successfully</div>
      )}
      <Form
        form={form}
        layout="vertical"
        style={{ maxWidth: "100%", margin: "0 auto" }}
        onFinish={onFinish}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} lg={12} >
            <label htmlFor="imageInput">Image Upload</label>
            <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              id="imageInput"
              ref={fileInputRef}
              onChange={handleFileChange}
              multiple
              min="1"
              max="12"
            />
            <Input
              placeholder="Upload image(s)"
              readOnly
              value={
                selectedFiles.length === 0
                  ? ""
                  : `${selectedFiles.length} file(s) selected`
              }
              onClick={handleClick}
              suffix={<UploadOutlined />}
              style={{
                cursor: "pointer",
                width: "100%",
                padding: "4px 11px",
                border: "1px solid #d9d9d9",
                borderRadius: "6px",
                backgroundColor: "#fff",
                fontSize: "14px",
                marginTop: "8px",
              }}
            />
          </Col>
          <Col lg={12} sm={24} xs={24}>
            <Form.Item
              name="subject"
              label="Subject"
              rules={[
                {
                  required: true,
                  message: "Please enter subject",
                },
              ]}
              style={{ marginBottom: "12px" }}
            >
              <Input placeholder="Enter subject" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col lg={12} sm={24} xs={24}>
            <Form.Item
              name="details"
              label="Detail"
              rules={[
                {
                  required: true,
                  message: "Please enter detail",
                },
              ]}
              style={{ marginBottom: "12px" }}
            >
              <Input.TextArea rows={3} placeholder="Enter detail" />
            </Form.Item>
          </Col>
          <Col lg={12} sm={24} xs={24}>
            <Form.Item
              name="ticketCategory"
              label="Ticket Category"
              rules={[
                {
                  required: true,
                  message: "Please select ticket category",
                },
              ]}
              style={{ marginBottom: "12px" }}
              initialValue="Select ticket category"
            >
              <Select value={ticketCategory} onChange={handleCategory}>
                <Option value="ITSoftware">IT Software</Option>
                <Option value="ITHardware">IT Hardware</Option>
                <Option value="Maintenance">Maintenance</Option>
                <Option value="Security Related">
                  Security related issue
                </Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        {showSubCategory && (
          <Row gutter={[16, 16]}>
            <Col lg={12} sm={24} xs={24}>
              <Form.Item
                name="maintenance_security"
                label="Maintenance Type"
                rules={[
                  { required: true, message: "Please select maintenance type" },
                ]}
              >
                <Select
                  placeholder="Select Maintenance Type"
                  value={subCategory}
                  onChange={handleSubCategory}
                >
                  <Option value="Electric">Electric</Option>
                  <Option value="Plumbing">Plumbing</Option>
                  <Option value="House Keeping">House Keeping</Option>
                  <Option value="Wood work">Wood work</Option>
                  <Option value="AC">AC</Option>
                  <Option value="Audio & Video">Audio & Video</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        )}
        {showIssue && (
          <Row gutter={[16, 16]}>
            <Col lg={12} sm={24} xs={24}>
              <Form.Item
                name="maintenance_security"
                label="Security Type"
                rules={[
                  { required: true, message: "Please select security type" },
                ]}
              >
                <Select placeholder="Select Security Type">
                  <Option value="LockUnlock">Lock Unlock</Option>
                  <Option value="ParkingIssue">Parking Issue</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        )}
        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit" loading={loading} className="bg-blue-500 text-white">
              Save 
            </Button>
           
          </Space>
        </Form.Item>
      </Form>
    </div>
    </div>
  );
};

export default Create;
