// QrCodeModal.js
import React from "react";
import { Modal } from "antd";
import QRCode from "react-qr-code";

const QrCodeModal = ({ qrCode, isVisible, onClose }) => {
  return (
    <Modal
      title="QR Code"
      visible={isVisible}
      onCancel={onClose}
      footer={null}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "500px",
      }}
    >
      <QRCode size={200} type="svg" value={qrCode} />
    </Modal>
  );
};

export default QrCodeModal;
