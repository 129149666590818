import {
   GET_OPEN_TICKET,
   GET_CLOSE_TICKET,
   GET_TICKET_NUMBER
  } from "../../../constants/actionTypes";
  import * as api from "../../../api";
  
  export const getOpenTicket= () => async (dispatch) => {
    try {
      const { data } = await api.getOpenTicket();
      dispatch({ type: GET_OPEN_TICKET, payload: data });
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  
  export const getCloseTicket = () => async (dispatch) => {
    try {
      const { data } = await api.getCloseTicket();
      dispatch({ type: GET_CLOSE_TICKET, payload: data });
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  
  export const getTicketNumber = () => async (dispatch) => {
    try {
      const { data } = await api.getTicketNumber();
      dispatch({ type: GET_TICKET_NUMBER, payload: data });
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

 
  