import React from "react";
import { Breadcrumb, Checkbox, Table } from "antd";

const data = [
  {
    key: "1",
    permission: "Members",
  },
  {
    key: "2",
    permission: "Permission",
  },
  {
    key: "3",
    permission: "Items",
  },
  {
    key: "4",
    permission: "Category",
  },
  {
    key: "5",
    permission: "Warehouse",
  },
  {
    key: "6",
    permission: "Elements",
  },
  {
    key: "7",
    permission: "Products",
  },
];

const columns = [
  {
    title: "Permission",
    dataIndex: "permission",
    key: "permission",
  },
  {
    title: "Create",
    dataIndex: "create",
    key: "create",
    render: (_, record) => <Checkbox />,
  },
  {
    title: "Update",
    dataIndex: "update",
    key: "update",
    render: (_, record) => <Checkbox />,
  },
  {
    title: "View",
    dataIndex: "view",
    key: "view",
    render: (_, record) => <Checkbox />,
  },
  {
    title: "Delete",
    dataIndex: "delete",
    key: "delete",
    render: (_, record) => <Checkbox />,
  },
];

const AddPermission = () => {
  return (
    <div>
      <div className="flex justify-between items-center pt-5 pl-4 pr-4">
        <h2 className="text-lg font-semibold text-gray-800">User Permission</h2>
        <Breadcrumb>
          <Breadcrumb.Item>
            <a href="/storeKeeper/dashboard">Home</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Permission</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="bg-white rounded-lg p-6 shadow-md m-5">
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <Table columns={columns} dataSource={data} pagination={false} />
        </div>
      </div>
    </div>
  );
};

export default AddPermission;
