import React, { useState, useEffect } from "react";
import { Table, Input, Space, DatePicker, Breadcrumb, message } from "antd";
import { useDispatch } from "react-redux";
import { getEmployeeAssets } from "../../../actions/employee/assets/assets";

const { Search } = Input;
const { RangePicker } = DatePicker;

const Assets = () => {
  const dispatch = useDispatch();

  const [assetsData, setAssetsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [categoryFilters, setCategoryFilters] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [totalPage, setTotalPage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const params = {
          page: currentPage,
          recordLimit: pageSize,
        };
        const result = await dispatch(getEmployeeAssets(params));
        setAssetsData(result.data);
        setTotalPage(result.totalPage);
      } catch (error) {
        console.error("Error fetching assets:", error);
        message.error(error.response.data.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, pageSize, currentPage]);

  useEffect(() => {
    // Extract unique asset categories from the data
    const categories = [
      ...new Set(assetsData.map((item) => item.assetCategory)),
    ];
    setCategoryFilters(
      categories.map((category) => ({ text: category, value: category }))
    );
  }, [assetsData]);

  const onSearch = (value) => {
    const filteredData = assetsData.filter((item) =>
      Object.values(item).some((val) =>
        val.toString().toLowerCase().includes(value.toLowerCase())
      )
    );
    setAssetsData(filteredData);
  };

  const onDateChange = (dates) => {
    // Implement date range filter logic here
    // Filter data based on the selected date range
    const filteredData = assetsData.filter(
      (item) =>
        new Date(item.createdAt) >= dates[0].startOf("day") &&
        new Date(item.createdAt) <= dates[1].endOf("day")
    );
    setAssetsData(filteredData);
  };

  const columns = [
    {
      title: "SNO",
      dataIndex: "sno",
      key: "sno",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Item Name",
      dataIndex: "itemName",
      key: "itemName",
    },
    {
      title: "Asset Category",
      dataIndex: "assetCategory",
      key: "assetCategory",
      filters: categoryFilters,
      onFilter: (value, record) => record.assetCategory === value,
    },
    {
      title: "Issue Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => new Date(createdAt).toLocaleDateString("en-GB"),
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    },
  ];

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };
  return (
    <div>
      <div className="flex justify-between items-center pt-5 pl-4 pr-4">
        <h2 className="text-lg font-semibold text-gray-800">Assets</h2>
        <Breadcrumb>
          <Breadcrumb.Item>
            {" "}
            <a href="/employee/dashboard">Home</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Assets</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="bg-white rounded-lg p-6 shadow-md m-5">
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: 16,
          }}
        >
          <Space>
            <Search placeholder="Search" onSearch={onSearch} />
            <RangePicker onChange={onDateChange} />
          </Space>
        </div>
        <div style={{ overflowX: "auto" }}>
          <Table
            dataSource={assetsData}
            columns={columns}
            loading={loading}
            className="center-table-data"
            pagination={{
              pageSize: pageSize,
              current: currentPage,
              onChange: handlePageChange,
              total: totalPage * pageSize,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Assets;
