import React, { useState } from "react";
import {
  Form,
  Button,
  message,
  Breadcrumb,
  Row,
  Col,
  Select,
  TimePicker,
} from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useDispatch } from "react-redux";
import { addEmployeeBooking } from "../../../actions/employee/booking/booking";

const { Option } = Select;

const Booking = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [successMsg, setSuccessMsg] = useState(false);

  dayjs.extend(customParseFormat);

  const onChange = (time, timeString) => {
    // console.log(time, timeString);
    setStartTime(time);
  };

  const onFinish = async (values) => {
    console.log("Received value", values);
    try {
      setLoading(true);
      let formattedTime, time;

      if (startTime && endTime) {
        formattedTime = startTime.toISOString();
        time = endTime.toISOString();
      }
      // console.log(formattedTime)
      const data = {
        startTime: formattedTime,
        endTime: time,
        bookingFor: values.booking,
        venue: values.venue,
      };
      const res = await dispatch(addEmployeeBooking(data));
      if (res.success) {
        message.success(res.message);
        setSuccessMsg(true);
        form.resetFields();
        setTimeout(() => {
          setSuccessMsg(false);
        }, 2000);
      }
    } catch (error) {
      console.log(error);
      message.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handleEndTimeChange = (time, timeString) => {
    setEndTime(time);
  };
  return (
    <div>
      <div className="flex justify-between items-center pt-5 pl-4 pr-4">
        <h2 className="text-lg font-semibold text-gray-800">Booking</h2>
        <Breadcrumb>
          <Breadcrumb.Item>
            {" "}
            <a href="/employee/dashboard">Home</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Booking</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="bg-white rounded-lg p-6 shadow-md m-5">
        {successMsg && (
          <div style={{ color: "green" }}>Booking created successfully!</div>
        )}
        <Form
          form={form}
          layout="vertical"
          style={{ maxWidth: "100%", margin: "0 auto" }}
          onFinish={onFinish}
        >
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} lg={12}>
              <Form.Item
                name="venue"
                label="Venue"
                rules={[{ required: true, message: "Please select venue!" }]}
              >
                <Select placeholder="Select Venue">
                  <Option value="ConferenceHall">Conference Hall</Option>
                  <Option value="KriyaHall">Kriya Hall</Option>
                  <Option value="Auditorium">Auditorium</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} lg={12}>
              <Form.Item
                label="Start Time"
                name="startTime"
                rules={[
                  { required: true, message: "Please select start time" },
                ]}
              >
                <TimePicker
                  onChange={onChange}
                  defaultOpenValue={dayjs("00:00:00", "HH:mm:ss")}
                  style={{ width: "100%" }}
                  placeholder="Select Start Time"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} lg={12}>
              <Form.Item
                label="End Time"
                name="endTime"
                rules={[{ required: true, message: "Please select end time" }]}
              >
                <TimePicker
                  onChange={handleEndTimeChange}
                  style={{ width: "100%" }}
                  defaultOpenValue={dayjs("00:00:00", "HH:mm:ss")}
                  placeholder="Select End Time"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} lg={12}>
              <Form.Item
                name="booking"
                label="Booking For"
                rules={[{ required: true, message: "Please select booking!" }]}
              >
                <Select placeholder="Select Booking">
                  <Option value="Meeting">Meeting</Option>
                  <Option value="Conference">Conference</Option>
                  <Option value="DelegationVisit">Delegation Visit</Option>
                  <Option value="Classes&SpecialLecture">
                    Classes / Special Lecture
                  </Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              className="bg-blue-500 text-white"
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Booking;
