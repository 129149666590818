import { GET_MAINTENANCE_EMPLOYEE } from "../../../constants/actionTypes";
import * as api from "../../../api";

export const getMaintenanceEmployee  = (params) => async (dispatch) => {
    try {
        const { data } = await api.getMaintenanceEmployee(params);
        // console.log(data)
        dispatch({ type: GET_MAINTENANCE_EMPLOYEE, payload: data });
        return data;
    } catch (error) {
        console.log(error);
        throw error;
    }
};