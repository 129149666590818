import React, { useState } from "react";
import { Breadcrumb, Tabs } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import TechnicianProfile from "./TechnicianProfile";
import MyAssets from "./MyAssets";

const { TabPane } = Tabs;

const Profile = () => {
  const [activeTab, setActiveTab] = useState("personalInfo");

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  return (
    <>
      <div className="flex justify-between items-center pt-5 pl-4 pr-4">
        <h2 className="text-lg font-semibold text-gray-800">Profile</h2>
        <Breadcrumb>
          <Breadcrumb.Item>
            {" "}
            <a href="/ITTechnician/dashboard">Home</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Profile</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="bg-white rounded-lg p-6 shadow-md m-5">

      <Tabs activeKey={activeTab} onChange={handleTabChange}>
        <TabPane tab="Personal Info" key="personalInfo">
          <TechnicianProfile />
        </TabPane>
        <TabPane tab="My Assets" key="myAssets">
            <MyAssets />
        </TabPane>
      </Tabs>
      </div>
    </>
  );
};

export default Profile;
