import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTachometerAlt,
  faUsers,
  faUserPlus,
  faListAlt,
  faFolder,
  faLaptop,
  faUser,
  faCalendarAlt,
  faSignOutAlt,
  faTicketAlt,
  faBars,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Layout, Menu, theme, message, Button } from "antd";
import { LOGOUT_ADMIN } from "../../../constants/actionTypes";
import Dashboard from "../dashboard/Dashboard";
import AddNew from "../employees/AddNew";
import AllEmployees from "../employees/AllEmployees";
import Profile from "../profile/Profile";
import AssetsCategory from "../assetsCategory/AssetsCategory";
import Ticket from "../ticket/Ticket";
import Assets from "../assets/Assets";
import { useDispatch } from "react-redux";
import Booking from "../booking/Booking";

const { Content, Sider, Header } = Layout;
const { SubMenu } = Menu;

const AdminLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const handleLogout = () => {
    dispatch({ type: LOGOUT_ADMIN });
    // console.log("User");
    message.success("Admin logout successfully!");
    navigate("/");
  };

  const carouselStyle = {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
  };
  const menuItems = [
    {
      key: "1",
      icon: <FontAwesomeIcon icon={faTachometerAlt} />,
      label: "Dashboard",
      link: "/admin/dashboard",
    },

    {
      key: "2",
      icon: <FontAwesomeIcon icon={faUsers} />,
      label: "Employees",
      subMenu: [
        {
          key: "2-1",
          label: "Add Employee",
          link: "/admin/add-employee",
          icon: <FontAwesomeIcon icon={faUserPlus} />,
        },
        {
          key: "2-2",
          label: "All Employees",
          link: "/admin/all-employees",
          icon: <FontAwesomeIcon icon={faListAlt} />,
        },
      ],
    },
    {
      key: "3",
      icon: <FontAwesomeIcon icon={faFolder} />,
      label: "Assets Category",
      link: "/admin/assets-category",
    },
    {
      key: "4",
      icon: <FontAwesomeIcon icon={faLaptop} />,
      label: "Assets(Items)",
      link: "/admin/assets",
    },
    {
      key: "5",
      icon: <FontAwesomeIcon icon={faTicketAlt} />,
      label: "Ticket",
      link: "/admin/admin-ticket",
    },
    {
      key: "6",
      icon: <FontAwesomeIcon icon={faUser} />,
      label: "Profile",
      link: "/admin/admin-profile",
    },
    {
      key: "7",
      icon: <FontAwesomeIcon icon={faCalendarAlt} />,
      label: "Booking",
      link: "/admin/booking",
    },
    {
      key: "8",
      label: "Logout",
      icon: <FontAwesomeIcon icon={faSignOutAlt} />,
      onClick: handleLogout,
    },
  ];
  const isDesktop = window.innerWidth > 992;

  return (
    <Layout>
      <Sider
        // breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
        width={260}
        style={{
          ...carouselStyle,
          overflowY: "auto",
          height: "100vh",
          position: isDesktop ? "fixed" : "static",
        }}
        collapsible
        collapsed={collapsed}
        trigger={null}
      >
        <div className="sider-header">
          <h3 className="text-xl text-white text-center p-3 font-bold">Admin</h3>
        </div>
        <Menu theme="dark" mode="inline">
          {menuItems.map((item) =>
            item.subMenu ? (
              <SubMenu key={item.key} icon={item.icon} title={item.label}>
                {item.subMenu.map((subItem) => (
                  <Menu.Item
                    key={subItem.key}
                    icon={subItem.icon}
                    style={{ paddingLeft: "30px" }}
                  >
                    <Link to={subItem.link}>{subItem.label}</Link>
                  </Menu.Item>
                ))}
              </SubMenu>
            ) : (
              <Menu.Item
                key={item.key}
                icon={item.icon}
                onClick={item.key === "8" ? item.onClick : null}
              >
                <Link to={item.link}>{item.label}</Link>
              </Menu.Item>
            )
          )}
        </Menu>
      </Sider>
    
      <Layout
       
      >
      {isDesktop ? null :( <Header
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        >
          <Button
            type="text"
            icon={
             collapsed ? (
               <FontAwesomeIcon icon={faTimes} />
             ) : (
               <FontAwesomeIcon icon={faBars} style={{ color: "#000" }} />
             )
           }
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: '16px',
              width: 64,
              height: 64,
            }}
          />
        </Header>)}
      

        <Content  style={{
          marginLeft: isDesktop ? 260 : 0,
          minHeight: "100vh",
          overflow: "auto",
          
        }}>
          {location.pathname === "/admin/dashboard" && <Dashboard />}
          {location.pathname === "/admin/add-employee" && <AddNew />}
          {location.pathname === "/admin/all-employees" && <AllEmployees />}
          {location.pathname === "/admin/admin-profile" && <Profile />}
          {location.pathname === "/admin/assets-category" && <AssetsCategory />}
          {location.pathname === "/admin/admin-ticket" && <Ticket />}
          {location.pathname === "/admin/assets" && <Assets />}
          {location.pathname === "/admin/booking" && <Booking />}
        </Content>
      </Layout>
    </Layout>
  );
};

export default AdminLayout;
