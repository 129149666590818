import axios from "axios";

const api = axios.create({
  // baseURL: "http://localhost:5000/",
  baseURL:"https://ticket-system-jv5h.onrender.com/",
  // baseURL:'https://blissful-solomon.97-74-83-236.plesk.page/'
});

api.interceptors.request.use(
  (req) => {
    if (localStorage.getItem("profile")) {
      req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem("profile")).authToken
        }`;
    }
    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const signin = (userInfo) => api.post(`common/login`, userInfo);
export const sendOtp = (otpInfo) => api.post(`common/sendOTP`, otpInfo);
export const verifyOtp = (otpInfo) => api.post(`common/verifyOTP`, otpInfo);
export const generatePassword = (userInfo) => api.post(`common/generatePassword`, userInfo);
export const addMember = (userInfo) =>
  api.post(`api/admin/registerMember`, userInfo);
export const getMember = (params) => api.get(`api/admin/employees`, { params });
export const getAdminProfile = () => api.get(`api/admin/admin`);
export const getAdminTicket = (params) => api.get(`api/admin/tickets`,{params});
export const addAssetsCategory = (categoryInfo) =>
  api.post(`api/admin/createAssetCategory`, categoryInfo);
export const getAssetsCategory = (params) => api.get(`api/admin/assetCategories`,{params});
export const getAdminAssets = (params) => api.get(`api/admin/assets`,{params});
export const getAdminAssetsById = (id) => api.get(`api/admin/assets/${id}`);
export const getAssignAssets = (id) => api.get(`api/admin/assetToEmployee/${id}`);
export const deleteAssetsCategory = (id) =>
  api.delete(`api/admin/deleteAssetCategory/${id}`);
export const getAdminTicketById = (id) => api.get(`api/admin/tickets/${id}`);
export const updateMember = ({ id, ...memberInfo }) => {
  return api.put(`api/admin/updateEmployee/${id}`, memberInfo);
};
export const getMemberById = (id) => api.get(`api/admin/employees/${id}`);
export const updateAdminAssets = ({ id, ...assetsInfo }) => {
  return api.put(`api/admin/updateAsset/${id}`, assetsInfo);
};
export const updateAdminAssetCategory = ({ id, ...assetsInfo }) => {
  return api.put(`api/admin/updateAssetCategory/${id}`, assetsInfo);
};
export const getTotalAsset = () => api.get(`api/admin/totalAsset`);
export const getTotalCategory = () => api.get(`api/admin/totalCategory`);
export const getTotalEmployee = () => api.get(`api/admin/totalEmployee`);
export const getTotalMember = () => api.get(`api/admin/totalMember`);
export const getAdminBooking = (params) => api.get(`api/admin/booking`,{params});
export const approveBooking = ({id,...data}) => {
  return api.put(`api/admin/approveOrDecline/${id}`,data);
};
export const deleteAssets = (id) =>
  api.delete(`api/admin/deleteAsset/${id}`);
export const getAssetsByCategory = (params) => api.get(`api/admin/assetsByCategories`,{params});

export const addMyTicket = (ticketInfo) => {
  return api.post(`api/employee/createTicket`, ticketInfo);
};
export const getMyTicket = (params) => api.get(`api/employee/myTickets`, { params });
export const changePasswordEmployee = (password) =>
  api.post(`api/employee/changePassword`, password);
export const getEmployeeProfile = () => api.get(`api/employee/employee`);
export const getEmployeeAssets = (params) => api.get(`api/employee/myAssets`,{params});
export const getEmployeeTicketById = (id) => api.get(`api/employee/myTickets/${id}`);
export const getDashboardEmployeeAsset = () => api.get(`api/employee/myAssetNumber`);
export const getDashboardEmployeeClose = () => api.get(`api/employee/myClosedTicket`);
export const getDashboardEmployeeOpen = () => api.get(`api/employee/myOpenTicket`);
export const updateEmployeeProfile = (data) => {
  return api.put(`api/employee/update`, data);
};
export const getDashboardEmployeeCategory = () => api.get(`api/employee/totalAssetCategory`);
export const addEmployeeBooking = (booking) => {
  return api.post(`api/employee/createBooking`, booking);
};
export const getEmployeeBooking = (params) => api.get(`api/employee/myBooking`,{params});

export const getTechnicianTicket = (params) => api.get(`api/iTTechnician/myTickets`,{params});
export const updateTicket = ({ id, ...data }) => {
  return api.put(`api/iTTechnician/updateTicket/${id}`, data);
};
export const getTechnicianEmployee = (params) => api.get(`api/iTTechnician/employees`,{params});
export const getTicketById = (id) => api.get(`api/iTTechnician/myTickets/${id}`);
export const changePasswordTechnician = (password) =>
  api.post(`api/iTTechnician/changePassword`, password);
export const getTechnicianProfile = () => api.get(`api/iTTechnician/iTechnician`);
export const addAssetsTechnician = (assets) =>
  api.post(`api/iTTechnician/assignAsset`, assets);
export const getAssetsTechnician = () => api.get(`api/iTTechnician/assets`);
export const getAssetsCategoryTechnician = () => api.get(`api/iTTechnician/assetCategories`);
export const getDashboardTechnicianTicket = () => api.get(`api/iTTechnician/myTicketNumber`);
export const getDashboardTechnicianClose = () => api.get(`api/iTTechnician/closedTicket`);
export const getDashboardTechnicianOpen = () => api.get(`api/iTTechnician/openTicket`);
export const updateTechnicianProfile = (data) => {
  return api.put(`api/iTTechnician/update`, data);
};


export const changePasswordStoreKeeper = (password) =>
  api.post(`api/storeKeeper/changePassword`, password);
export const getStoreKeeperProfile = () => api.get(`api/storeKeeper/storeKeeper`);
export const addAssetsCategoryStore = (categoryInfo) =>
  api.post(`api/storeKeeper/createAssetCategory`, categoryInfo);
export const getAssetsCategoryStore = (params) => api.get(`api/storeKeeper/assetCategories`,{params});
export const addAssets = (assetsInfo) =>
  api.post(`api/storeKeeper/createAsset`, assetsInfo);
export const getAssets = (params) => api.get(`api/storeKeeper/assets`,{params});
export const updateAssets = ({ id, ...data }) => {
  return api.put(`api/storeKeeper/updateAsset/${id}`, data);
};
export const getAssetsById = (id) => api.get(`api/storeKeeper/assets/${id}`);
export const updateStoreKeeperProfile = (data) => {
  return api.put(`api/storeKeeper/update`, data);
};
export const getStoreTotalAsset = () => api.get(`api/storeKeeper/totalAsset`);
export const getStoreTotalCategory = () => api.get(`api/storeKeeper/totalCategory`);
export const getStoreTotalMember = () => api.get(`api/storeKeeper/totalMember`);
export const updateStoreAssetCategory = ({ id, ...assetsInfo }) => {
  return api.put(`api/storeKeeper/updateAssetCategory/${id}`, assetsInfo);
};
export const getStoreAssetsByCategory = (params) => api.get(`api/storeKeeper/assetsByCategories`,{params});


export const getMaintenanceProfile = () => api.get(`api/maintenance/maintenance`);
export const updateMaintenanceProfile = (data) => {
  return api.put(`api/maintenance/update`, data);
};

export const changePasswordMaintenance = (password) =>
  api.post(`api/maintenance/changePassword`, password);

  export const getMaintenanceTicket = (params) => api.get(`api/maintenance/myTickets`,{params});
  export const getMaintenanceBooking = (params) => api.get(`api/maintenance/myBooking`,{params});
  export const getMaintenanceEmployee = (params) => api.get(`api/maintenance/employees`,{params});

  export const getTicketNumber = () => api.get(`api/maintenance/myTicketNumber`);
  export const getOpenTicket = () => api.get(`api/maintenance/openTicket`);
  export const getCloseTicket = () => api.get(`api/maintenance/closedTicket`);

  export const updateBooking = ({ id, ...data }) => {
    return api.put(`api/maintenance/updateBooking/${id}`, data);
  };

  export const updateMaintenanceTicket = ({ id, ...data }) => {
    return api.put(`api/maintenance/updateTicket/${id}`, data);
  };