import * as actionTypes from '../../../constants/actionTypes';

const initialState = {
    ticket: [],
    state: 'idle', 
    error: null
};

export const maintenanceTicketReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_MAINTENANCE_TICKET:
            return {
                ...state,
                ticket: action.payload,
            };
            case actionTypes.UPDATE_MAINTENANCE_TICKET:
                return {
                  ...state,
                  success: action.payload,
                  error: null,
                };
          
        default:
            return state;
    }
};

export default maintenanceTicketReducer;