import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  Table,
  Button,
  Modal,
  Form,
  Input,
  message,
  Spin,
} from "antd";
import { useDispatch } from "react-redux";
import {  EditOutlined } from "@ant-design/icons";
import { getMaintenanceProfile, updateMaintenanceProfile } from "../../../actions/maintenance/profile/profile";

const Profile = () => {
  const dispatch = useDispatch();
  const [form]=Form.useForm()
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await dispatch(getMaintenanceProfile());
        setData([res.data]);
        if (res && res.data) {
            form.setFieldsValue({
              name: res.data.name,
              mobileNumber: res.data.mobileNumber,
              email: res.data.email,
              post: res.data.post,
              attendanceId: res.data.attendanceId,
              department: res.data.department,
            });
          } else {
            console.error("Data not found.");
          }
      } catch (error) {
        console.error("Error fetching assets:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch,form]);

  const handleEditClick = () => {
    setVisible(true);
  };

    const handleUpdate = async (values) => {
      try {
        setLoading(true);
        const res = await dispatch(updateMaintenanceProfile({name:values.name}));
        if (res.success) {
          message.success(res.message);
          setVisible(false);
        } else {
          message.error(res.message);
        }
      } catch (error) {
        console.error("Error updating profile:", error);
        message.error(error.response.data.message);
      }finally{
        setLoading(false);
      }
    };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Mobile Number",
      dataIndex: "mobileNumber",
      key: "mobileNumber",
    },
    {
      title: "Post",
      dataIndex: "post",
      key: "post",
    },
    {
      title: "Attendance Id",
      dataIndex: "attendanceId",
      key: "attendanceId",
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
    },

    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button type="link" onClick={() => handleEditClick(record)}>
          <EditOutlined />
        </Button>
      ),
    },
  ];

  return (
    <div>
   
      <div className="flex justify-between items-center pt-5 pl-4 pr-4">
        <h2 className="text-lg font-semibold text-gray-800">Profile</h2>
        <Breadcrumb>
        <Breadcrumb.Item>
            <a href="/maintenance/dashboard">
             Home
            </a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Profile</Breadcrumb.Item>
        </Breadcrumb>
      
       
      </div>
      <div className="bg-white rounded-lg p-6 shadow-md m-5">
      <div>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin size="small" />
          </div>
        ) : (
          <div style={{ overflowX: "auto" }}>
            <Table columns={columns} dataSource={data} pagination={false} />
          </div>
        )}

        <Modal
          title="Update Profile"
          visible={visible}
          onCancel={() => setVisible(false)}
          footer={null}
        >
          <Form
          form ={form}
            onFinish={handleUpdate}
            layout="vertical"
          >
            <Form.Item label="Name" name="name">
              <Input placeholder="Name" />
            </Form.Item>
            <Form.Item label="Email" name="email">
              <Input disabled placeholder="Email" />
            </Form.Item>
            <Form.Item label="Mobile Number" name="mobileNumber">
              <Input disabled placeholder="MobileNumber" />
            </Form.Item>
            <Form.Item label="Post" name="post">
              <Input disabled placeholder="Post" />
            </Form.Item>
            <Form.Item label="Attendance Id" name="attendanceId">
              <Input disabled placeholder="Attendance Id" />
            </Form.Item>
            <Form.Item label="Department" name="department">
              <Input disabled placeholder="Department" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading} className="bg-blue-500 text-white">
                Update
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </div>
    </div>
  );
};

export default Profile;
