
import React,{useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTachometerAlt,
  faLock,
  faCalendarPlus,
  faListAlt,
  faLaptop,
  faUser,
  faCalendarAlt,
  faSignOutAlt,
  faTicketAlt,
  faBars,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import { useParams, Link, useNavigate } from "react-router-dom";
import { Layout, Menu, theme, message,Button } from "antd";
import { useDispatch } from "react-redux";
import { LOGOUT_EMPLOYEE } from "../../../constants/actionTypes";
import EmployeeTimeLine from "./EmployeeTimeLine";


const { Content, Sider,Header } = Layout;
const { SubMenu } = Menu;

const EmployeeTLine = () => {
  const { ticketId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isDesktop = window.innerWidth > 992;
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const handleLogout = () => {
    dispatch({ type: LOGOUT_EMPLOYEE });
    message.success("Employee logout successfully!");
    navigate("/");
  };

  const menuItems = [
    {
      key: "1",
      icon:   <FontAwesomeIcon icon={faTachometerAlt} /> ,
      label: "Dashboard",
      link: "/employee/dashboard",
    },

    {
      key: "2",
      icon:    <FontAwesomeIcon icon={faTicketAlt} /> ,
      label: "Ticket",
      subMenu: [
        {
          key: "2-1",
          label: "Create",
          link: "/employee/create-ticket",
          icon:    <FontAwesomeIcon icon={faTicketAlt} /> ,
        },
        {
          key: "2-2",
          label: "My Ticket",
          link: "/employee/my-ticket",
          icon: <FontAwesomeIcon icon={faListAlt} /> ,
        },
      ],
    },
    {
      key: "3",
      icon:  <FontAwesomeIcon icon={faLaptop} />,
      label: "Assets",
      link: "/employee/assets",
    },
    {
      key: "4",
      icon:  <FontAwesomeIcon icon={faUser} />,
      label: "Profile",
      subMenu: [
        {
          key: "4-1",
          label: "Profile",
          link: "/employee/employee-profile",
          icon:  <FontAwesomeIcon icon={faUser} />,
        },
        {
          key: "4-2",
          label: "Change Password",
          link: "/employee/employee-change-password",
          icon:  <FontAwesomeIcon icon={faLock} />,
        },
      ],
    },
    {
      key: "5",
      icon: <FontAwesomeIcon icon={faCalendarAlt} />,
      label: "Booking",
      subMenu: [
        {
          key: "5-1",
          label: "Create Booking",
          link: "/employee/booking",
          icon:  <FontAwesomeIcon icon={faCalendarPlus} />,
        },
        {
          key: "5-2",
          label: "All Booking",
          link: "/employee/allBooking",
          icon:  <FontAwesomeIcon icon={faBars} />,
        },
      ],
    },

    {
      key: "6",
      label: "Logout",
      icon:  <FontAwesomeIcon icon={faSignOutAlt} />,
      onClick: handleLogout,
    },
  ];

  const carouselStyle = {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
  };

  return (
    <Layout>
      <Sider
        // breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
        width={260}
        style={{
          ...carouselStyle,
          overflowY: "auto",
          height: "100vh",
          position: isDesktop ? "fixed" : "static",
        }}
        collapsible
        collapsed={collapsed}
        trigger={null}
      >
       <div className="sider-header">
          <h3 className="text-xl text-white text-center p-3 font-bold">Employee</h3>
        </div>
        <Menu
          theme="dark"
          mode="inline"
        
        >
          {menuItems.map((item) =>
            item.subMenu ? (
              <SubMenu key={item.key} icon={item.icon} title={item.label}>
                {item.subMenu.map((subItem) => (
                  <Menu.Item
                    key={subItem.key}
                    icon={subItem.icon}
                    style={{ paddingLeft: "30px" }}
                  >
                    <Link to={subItem.link}>{subItem.label}</Link>
                  </Menu.Item>
                ))}
              </SubMenu>
            ) : (
              <Menu.Item
                key={item.key}
                icon={item.icon}
                onClick={item.key === "6" ? item.onClick : null}
              >
                <Link to={item.link}>{item.label}</Link>
              </Menu.Item>
            )
          )}
        </Menu>
      </Sider>

      <Layout
      >
       {isDesktop ? null :( <Header
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        >
          <Button
            type="text"
            icon={
             collapsed ? (
               <FontAwesomeIcon icon={faTimes} />
             ) : (
               <FontAwesomeIcon icon={faBars} style={{ color: "#000" }} />
             )
           }
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: '16px',
              width: 64,
              height: 64,
            }}
          />
        </Header>)}
        <Content
         style={{
          marginLeft: isDesktop ? 260 : 0,
          minHeight: "100vh",
          overflow: "auto",
          
        }}>
          <div
            style={{
            
              minHeight: 450,
           
            }}
          >
           <EmployeeTimeLine ticketId={ticketId} />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default EmployeeTLine;
